<template>
  <icon class="icon icon--add">
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      class="icon__canvas"
      width="24"
    >
      <path
        d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"
        class="icon__glyph icon__glyph--add"
      />
    </svg>
  </icon>
</template>

<script>
import Icon from "./IconBase";

export default {
  name: "AddIcon",
  components: {
    Icon,
  },
};
</script>
