/**
 * Helper file to abstract some methods away from an
 * ever-growing PDTF composable.
 */
import participantRoles from "../enums/participant-roles";
import { usePDTF } from "@/composables/pdtf";
import { computed } from "vue";

const { pdtfState } = usePDTF();

/**
 * Add the index of the participant to the participant object
 * as a shorthand to avoid having to determine it every time.
 * By now, the index may not be needed anymore.
 */
const getParticipants = (state) => {
  const participants = state?.participants;

  if (!Array.isArray(participants)) return [];

  return participants.map((participant, index) => ({
    ...participant,
    index,
  }));
};

/**
 * Add the schema index as it's used a lot, and it's quicker than
 * interrogating it every time.
 */
const participants = computed(() => getParticipants(pdtfState.value));

const findParticipantByUserId = (userId) => {
  const match = participants.value.find(
    (participant) => participant?.externalIds?.Moverly === userId
  );

  return match;
};

const getParticipantsByRole = (roles, stateToUse) => {
  const rolesToFind = Array.isArray(roles) ? roles : [roles];
  const participantsToSearch = stateToUse || participants.value;

  if (!Array.isArray(participantsToSearch)) return [];

  const filteredParticipants = participantsToSearch.filter((participant) =>
    rolesToFind.includes(participant.role)
  );

  return filteredParticipants;
};

const formatFullParticipantName = (
  participant,
  options = { includeTitle: false }
) => {
  const {
    firstName = "",
    middleName = "",
    lastName = "",
    title = "",
  } = participant?.name || {};
  const concatentatedName = middleName
    ? `${firstName} ${middleName} ${lastName}`
    : `${firstName} ${lastName}`.trim();

  if (options?.includeTitle) {
    return `${title} ${concatentatedName}`.trim();
  }

  return concatentatedName;
};
const formatExternalIds = (userId) => {
  return {
    Moverly: userId,
  };
};

const isParticipantForTransaction = (
  userProfileTransactions,
  transactionId
) => {
  if (!userProfileTransactions) return false;

  return userProfileTransactions
    .map((rel) => rel.transactionId)
    .includes(transactionId);
};

export default function useParticipants() {
  return {
    findParticipantByUserId,
    formatExternalIds,
    formatFullParticipantName,
    getParticipants,
    getParticipantsByRole,
    isParticipantForTransaction,
    participantRoles,
    participants,
  };
}
