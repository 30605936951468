import App from "./App.vue";
import HoneybadgerVue from "@honeybadger-io/vue";
import router from "./router";
import {
  GTM_AUTH,
  GTM_DISABLE_PUSH_USER_DATA,
  GTM_ID,
  GTM_PREVIEW,
  HONEYBADGER_API_KEY,
  HONEYBADGER_ENV,
  APP_VERSION,
  HONEYBADGER_DISABLE_LOGGING,
} from "@/config";
import { auth } from "./firebase";
import { createApp } from "vue/dist/vue.esm-bundler";
import { createGtm, useGtm } from "@gtm-support/vue-gtm";
import { onAuthStateChanged } from "firebase/auth";

let app = null;

onAuthStateChanged(auth, () => {
  if (!app) {
    app = createApp(App);
    app.use(router);

    /**
     * Hot reloading during development can cause infinite loops in Honeybadger
     * Add `VUE_APP_HONEYBADGER_DISABLE_LOGGING=true` to your `.env` to
     * disable it. It is left on by default for production environments.
     */
    if (HONEYBADGER_DISABLE_LOGGING !== "true") {
      app.use(HoneybadgerVue, {
        apiKey: HONEYBADGER_API_KEY,
        environment: HONEYBADGER_ENV,
        revision: APP_VERSION,
        /**
         * Disabled unhandle promise rejections because of this Firebase
         * issue: https://github.com/firebase/firebase-js-sdk/issues/6708
         */
        enableUnhandledRejection: false,
      });
    }

    app.use(
      createGtm({
        id: GTM_ID,
        queryParams: {
          gtm_auth: GTM_AUTH,
          gtm_preview: GTM_PREVIEW,
          gtm_cookies_win: "x",
        },
        vueRouter: router,
      })
    );
    app.mount("#app");
  }

  // Avoiding pushing any PII.
  if (app.$honeybadger && auth.currentUser?.uid) {
    app.$honeybadger.setContext({
      user_id: auth.currentUser.uid,
    });
  }

  // TODO: Is this still required?
  if (GTM_DISABLE_PUSH_USER_DATA !== "true") {
    const gtm = useGtm();
    const currentUser = auth?.currentUser;
    // Convert the Firestore millisecond timestamp to Intercom's seconds format.
    const createdAt = currentUser?.metadata?.createdAt
      ? Math.round(Number(currentUser.metadata.createdAt) / 1000)
      : null;

    gtm.trackEvent({
      event: "user_update",
      userId: currentUser?.uid || null,
      userCreatedAt: createdAt,
    });
  }
});
