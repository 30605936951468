import impersonateUser from "../guards/impersonateUser";
import redirectIfUsingConfiguredProducts from "../guards/redirectIfUsingConfiguredProducts";
import requiresAuth from "../guards/requiresAuth";
import requiresOrganisationUsers from "../guards/requiresOrganisationUsers";
import requiresOrganisationOffices from "../guards/requiresOrganisationOffices";
import requiresUserProfile from "../guards/requiresUserProfile";

export default [
  {
    path: "/properties",
    name: "back-office.properties",
    beforeEnter: [
      requiresAuth,
      requiresUserProfile,
      requiresOrganisationUsers,
      requiresOrganisationOffices,
    ],
    component: {
      template: `<router-view></router-view>`,
    },
    meta: {
      title: "Properties",
      layout: "BackOfficeLayout",
    },
    children: [
      {
        path: "add",
        name: "agent.transaction.create",
        component: () =>
          import(
            /* webpackChunkName: "back-office" */ "../../views/back-office/transactions/CreateTransaction.vue"
          ),
        meta: {
          title: "Add property",
        },
      },
      {
        path: "/agent/properties",
        name: "back-office.dashboard",
        component: () =>
          import(
            /* webpackChunkName: "back-office" */ "../../views/back-office/transactions/PropertiesDashboard.vue"
          ),
        meta: {
          title: "View properties",
        },
      },
      {
        path: ":transactionId",
        component: () =>
          import(
            /* webpackChunkName: "back-office" */ "../../views/back-office/BackOfficeTransactionRouter.vue"
          ),
        children: [
          /**
           * This could be a local emulators issue, but this route is here
           * as the page loads with empty data; Firebase hasn't updated yet.
           * This has started since the v. claims are fetched with an endpoint
           * not a snapshot listener.
           */
          {
            path: "view",
            name: "agent.transaction.view",
            component: () =>
              import(
                /* webpackChunkName: "back-office" */ "../../views/back-office/transactions/ViewTransaction.vue"
              ),
            meta: {
              title: "View property",
            },
          },
          {
            path: "view/upgrade/payment",
            name: "back-office.upgrade.billing",
            component: () =>
              import(
                /* webpackChunkName: "back-office" */ "../../views/back-office/transactions/upgrade/UpgradeBilling.vue"
              ),
            meta: {
              title: "Payment",
            },
          },
          {
            path: "view/upgrade/options",
            name: "agent.transaction.upgrade.options",
            beforeEnter: redirectIfUsingConfiguredProducts,
            component: () =>
              import(
                /* webpackChunkName: "back-office" */ "../../views/back-office/transactions/upgrade/UpgradeOptions.vue"
              ),
            meta: {
              title: "Service options",
            },
          },
          {
            path: "view/upgrade/titles",
            name: "agent.transaction.upgrade.titles",
            component: () =>
              import(
                /* webpackChunkName: "back-office" */ "../../views/back-office/transactions/upgrade/VerifyTitles.vue"
              ),
            meta: {
              title: "Add property",
            },
          },
          {
            path: "view/upgrade/invites",
            name: "agent.transaction.upgrade.invites",
            component: () =>
              import(
                /* webpackChunkName: "back-office" */ "../../views/back-office/transactions/upgrade/InviteParticipants.vue"
              ),
            meta: {
              title: "Add vendors",
            },
          },
          {
            path: "view/upgrade/connells-home-conveyancing",
            name: "agent.transaction.upgrade.connells.home-conveyancing-upsell",
            component: () =>
              import(
                /* webpackChunkName: "back-office" */ "../../views/back-office/transactions/upgrade/ConnellsHomeConveyancingUpsell.vue"
              ),
            meta: {
              title: "Service options",
            },
          },
          {
            path: "view/upgrade/review",
            name: "agent.transaction.upgrade.review",
            component: () =>
              import(
                /* webpackChunkName: "back-office" */ "../../views/back-office/transactions/upgrade/ReviewUpgrade.vue"
              ),
            meta: {
              title: "Review",
            },
          },
          {
            path: "view/upgrade/review/connells",
            name: "agent.transaction.upgrade.connells.review",
            component: () =>
              import(
                /* webpackChunkName: "back-office" */ "../../views/back-office/transactions/upgrade/ConnellsReviewUpgrade.vue"
              ),
            meta: {
              title: "Review",
            },
          },
        ],
      },
    ],
  },
  /**
   * The certificate route is a special case - it expects the generatePdfDownload
   * method to log in in the back-end to generate a PDF of the certificate page.
   */
  {
    path: "/properties/:transactionId",
    beforeEnter: [
      impersonateUser,
      requiresUserProfile,
      requiresOrganisationUsers,
    ],
    component: () =>
      import(
        /* webpackChunkName: "back-office" */ "../../views/back-office/BackOfficeTransactionRouter.vue"
      ),
    children: [
      {
        path: "certificate",
        name: "agent.transaction.certificate",
        component: () =>
          import("../../views/back-office/transactions/ViewCertificate.vue"),
        meta: {
          title: "Material Information Certificate",
          layout: "PrintLayout",
        },
      },
    ],
  },
  {
    path: "/admin",
    name: "back-office.admin",
    beforeEnter: [requiresAuth, requiresUserProfile, requiresOrganisationUsers],
    component: {
      template: `<router-view></router-view>`,
    },
    meta: {
      title: "Admin",
      layout: "BackOfficeLayout",
    },
    children: [
      {
        path: "transactions",
        name: "back-office.admin.transactions.list",
        component: () =>
          import(
            /* webpackChunkName: "back-office.admin" */ "../../views/back-office/admin/ListAdminTransactions.vue"
          ),
        meta: {
          title: "Organisations",
        },
      },
      {
        path: "tpfg",
        name: "back-office.admin.report.tpfg",
        component: () =>
          import(
            /* webpackChunkName: "back-office.admin" */ "../../views/back-office/admin/TpfgReport.vue"
          ),
        meta: {
          title: "TPFG",
        },
      },
      {
        path: "reports/organisation",
        name: "back-office.admin.report.organisation",
        component: () =>
          import(
            /* webpackChunkName: "back-office.admin" */ "../../views/back-office/admin/OrganisationReport.vue"
          ),
        meta: {
          title: "Lead reporting",
        },
      },
      {
        path: "organisations",
        name: "back-office.admin.organisation.list",
        component: () =>
          import(
            /* webpackChunkName: "back-office.admin" */ "../../views/back-office/admin/ListOrganisations.vue"
          ),
        meta: {
          title: "Organisations",
        },
      },
      {
        path: "emails",
        name: "back-office.admin.emails",
        component: () =>
          import(
            /* webpackChunkName: "back-office.admin" */ "../../views/back-office/admin/AdminEmails.vue"
          ),
        meta: {
          title: "Organisations",
        },
      },
      {
        path: "webhooks",
        name: "back-office.admin.webhooks",
        component: () =>
          import(
            /* webpackChunkName: "back-office.admin" */ "../../views/back-office/admin/Webhooks.vue"
          ),
        meta: {
          title: "Webhooks",
        },
      },

      {
        path: "users",
        name: "back-office.admin.users",
        component: () =>
          import(
            /* webpackChunkName: "back-office.admin" */ "../../views/back-office/admin/Users.vue"
          ),
        meta: {
          title: "Users",
        },
      },
      {
        path: "tools",
        name: "back-office.admin.tools",
        component: () =>
          import(
            /* webpackChunkName: "back-office.admin" */ "../../views/back-office/admin/Tools.vue"
          ),
        meta: {
          title: "Tools",
        },
      },
      {
        path: "organisations/:organisationId",
        name: "back-office.admin.organisation.edit",
        props: (route) => {
          const { organisationId } = route.params;

          return { organisationId };
        },
        component: () =>
          import(
            /* webpackChunkName: "back-office.admin" */ "../../views/back-office/admin/ManageOrganisation.vue"
          ),
        meta: {
          title: "Manage",
        },
      },
      {
        path: "transactions/:transactionId",
        name: "back-office.admin.transaction.view",
        props: (route) => {
          const { transactionId } = route.params;

          return { transactionId };
        },
        component: () =>
          import(
            /* webpackChunkName: "back-office.admin" */ "../../views/back-office/admin/OrganisationTransaction.vue"
          ),
        meta: {
          title: "Manage",
        },
      },
      {
        path: "organisations/:organisationId/transactions/:transactionId",
        name: "back-office.admin.organisation.transaction.view",
        props: (route) => {
          const { organisationId, transactionId } = route.params;

          return { organisationId, transactionId };
        },
        component: () =>
          import(
            /* webpackChunkName: "back-office.admin" */ "../../views/back-office/admin/OrganisationTransaction.vue"
          ),
        meta: {
          title: "Manage",
        },
      },
    ],
  },
  {
    path: "/organisation",
    name: "back-office.organisation",
    beforeEnter: [requiresAuth, requiresUserProfile, requiresOrganisationUsers],
    component: {
      template: `<router-view></router-view>`,
    },
    meta: {
      title: "Organisation",
      layout: "BackOfficeLayout",
    },
    children: [
      {
        path: "settings",
        name: "back-office.organisation.details",
        component: () =>
          import(
            /* webpackChunkName: "back-office.organisation" */ "../../views/back-office/organisation/ViewOrganisation.vue"
          ),
        meta: {
          title: "Settings",
        },
      },
      {
        path: "help",
        name: "back-office.organisation.help",
        component: () =>
          import(
            /* webpackChunkName: "back-office.organisation" */ "../../views/back-office/organisation/BackOfficeHelp.vue"
          ),
        meta: {
          title: "Help &amp; support",
        },
      },
      {
        path: "/organisation/offices",
        name: "back-office.organisation.offices.list",
        component: () =>
          import(
            /* webpackChunkName: "back-office.organisation" */ "../../views/back-office/organisation/offices/ListOffices.vue"
          ),
        meta: {
          title: "Offices",
        },
      },
      {
        path: "/organisation/offices/add",
        name: "back-office.organisation.offices.add",
        component: () =>
          import(
            /* webpackChunkName: "back-office.organisation" */ "../../views/back-office/organisation/offices/AddOffice.vue"
          ),
        meta: {
          title: "Add office",
        },
      },
      {
        path: "/organisation/offices/:officeId",
        name: "back-office.organisation.offices.manage",
        props: (route) => {
          const { officeId } = route.params;

          return { officeId };
        },
        component: () =>
          import(
            /* webpackChunkName: "back-office.organisation" */ "../../views/back-office/organisation/offices/ManageOffice.vue"
          ),
        meta: {
          title: "Manage office",
        },
      },
    ],
  },
  {
    path: "/organisation/user/account",
    name: "back-office.user.account",
    beforeEnter: [requiresAuth, requiresUserProfile, requiresOrganisationUsers],
    component: () =>
      import("../../views/back-office/organisation/ViewAccount.vue"),

    meta: {
      title: "My account",
      layout: "BackOfficeLayout",
    },
  },
  {
    path: "/payment/3dsecure",
    name: "back-office.stripe.3dsecure",
    component: () =>
      import(
        /* webpackChunkName: "upfront-searches" */ "../../views/back-office/3DSecure.vue"
      ),
    meta: {
      title: "Loading",
      layout: "EmptyLayout",
    },
  },
];
