import useMeta from "@/composables/meta";

const { formatTitle, setTitle } = useMeta();

const getTitlesByRoutes = (routes) => {
  const titles = routes.reduce((titles, route) => {
    if (!route?.meta?.title) return titles;

    if (Array.isArray(route.meta.title)) {
      return [...route.meta.title.reverse(), ...titles];
    }

    return [route.meta.title, ...titles];
  }, []);

  return titles;
};

const setRouteTitle = ({ router, appName = "" }) => {
  router.afterEach((to) => {
    const titles = getTitlesByRoutes(to.matched);
    const formattedPageTitle = formatTitle(titles, appName);
    setTitle(formattedPageTitle);
  });
};

export default setRouteTitle;
