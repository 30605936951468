export default [
  {
    path: "/checks/:transactionId",
    name: "checks",
    component: () => import("../../views/_development/TransactionChecks.vue"),
    meta: {
      title: "Checks",
    },
  },
  {
    path: "/example",
    name: "example",
    component: () => import("../../views/_development/ComponentExamples.vue"),
    meta: {
      title: "Component examples",
    },
  },
  {
    path: "/email",
    name: "email",
    component: () => import("../../views/_development/EmailSignature.vue"),
    meta: {
      title: "Email",
    },
  },
  {
    path: "/vault-integration",
    name: "vault-integration",
    component: () => import("../../views/_development/VaultIntegration.vue"),
    meta: {
      title: "Vault integration",
    },
  },
];
