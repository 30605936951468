import { ZENDESK_JWT_KEY_ID, ZENDESK_JWT_SECRET } from "@/config";
import { SignJWT } from "jose";
import useAuth from "@/composables/auth";
import useUserProfile from "@/composables/user/userProfile";

const ZENDESK_CUSTOM_FIELD_WEBCHAT_HELP_PATH = 4513731629599;

// Refs to user and user profile
const { fullName } = useUserProfile();
const { user } = useAuth();

const showZendeskChat = () => {
  // Check if zE is a function before calling it
  if (isZendeskReady()) {
    window.zE("messenger", "open");
  } else {
    console.warn("Zendesk widget is not available.");
    window.open("https://support.moverly.com", "_blank");
  }
};

const waitAndHideZendeskWidget = () => {
  const checkZendesk = setInterval(() => {
    if (isZendeskReady()) {
      clearInterval(checkZendesk); // Stop the interval once ready
      window.zE("messenger", "hide");
    }
  }, 50); // Poll every 50ms
};

const waitAndShowZendeskWidget = () => {
  const checkZendesk = setInterval(() => {
    if (isZendeskReady()) {
      clearInterval(checkZendesk); // Stop the interval once ready
      window.zE("messenger", "show");
    }
  }, 50); // Poll every 50ms
};

const isZendeskReady = () => typeof window.zE === "function";

const injectFieldValue = (fieldID, value) => {
  if (isZendeskReady()) {
    window.zE("messenger:set", "conversationFields", [
      { id: fieldID, value: value },
    ]);
  }
};

// Set up the widget so that, once it's opened, we authenticate the user
const zendeskSetupAuth = () => {
  if (isZendeskReady()) {
    // Messenger is opened - login if appropriate
    window.zE("messenger:on", "open", async function () {
      if (user.value) {
        zendeskAuthenticateUser(user.value, fullName.value);
      } else {
        console.warn(
          "User is not logged in, so not authenticating with Zendesk."
        );
      }
    });
  }
};

// See https://developer.zendesk.com/documentation/zendesk-web-widget-sdks/sdks/web/enabling_auth_visitors/ and https://developer.zendesk.com/api-reference/widget-messaging/web/authentication/
const createJWT = async (userObject, fullName) => {
  // Construct the payload for the JWT
  const payload = {
    email: userObject.email,
    email_verified: userObject.emailVerified,
    external_id: userObject.uid,
    scope: "user", // MUST be provided or the backend will return a 401
  };
  if (fullName) {
    payload.name = fullName;
  }

  // Create and sign the JWT token
  try {
    const token = await new SignJWT(payload)
      .setProtectedHeader({ alg: "HS256", typ: "JWT", kid: ZENDESK_JWT_KEY_ID })
      .setIssuedAt()
      .setExpirationTime("2hrs")
      .sign(new TextEncoder().encode(ZENDESK_JWT_SECRET));

    return token;
  } catch (error) {
    console.error("Error creating JWT:", error);
    throw error;
  }
};

const zendeskAuthenticateUser = (userObject, fullName) => {
  if (isZendeskReady()) {
    window.zE("messenger", "loginUser", async function (callback) {
      try {
        const token = createJWT(userObject, fullName);
        callback(token);
      } catch (error) {
        console.error("Error generating JWT token:", error);
      }
    });
  }
};

const zendeskSetHelpPath = (path) => {
  injectFieldValue(ZENDESK_CUSTOM_FIELD_WEBCHAT_HELP_PATH, path);
};

export default function useZendesk() {
  return {
    isZendeskReady,
    showZendeskChat,
    waitAndHideZendeskWidget, // for printables
    waitAndShowZendeskWidget,
    zendeskAuthenticateUser, // Make sure to export the method
    zendeskSetHelpPath,
    zendeskSetupAuth,
  };
}
