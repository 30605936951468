import traverse from "traverse";
import jp from "jsonpointer";
/**
 * Just made this utils collection into a new composable to prevent a refactor of the
 * default export of the verifiedClaims composable as
 * that might not be a worthwhile tech debt to pay off.
 */

const getClaimsMap = (verifiedClaims) => {
  const claimsMap = {};
  verifiedClaims.forEach((verifiedClaim) => {
    Object.entries(verifiedClaim.claims).forEach(([claimPath, claimObject]) => {
      let subIndex = 0;
      // if the path ends with a dash, it means it is an array append
      let indexedClaimPath = claimPath;
      if (claimPath.endsWith("-")) {
        const existingIndexedObject = jp.get(claimsMap, claimPath.slice(0, -2));
        if (existingIndexedObject) {
          subIndex = existingIndexedObject.length;
        }
        indexedClaimPath = `${claimPath.slice(0, -2)}/${subIndex}`;
      }
      traverse(claimObject).forEach(function () {
        if (this.isLeaf) {
          const fullPath =
            // json pointer is weird with "/" as the path
            this.path.length === 0
              ? indexedClaimPath
              : indexedClaimPath + "/" + this.path.join("/");
          const existing = jp.get(claimsMap, fullPath);
          if (existing && Array.isArray(existing)) {
            jp.set(claimsMap, fullPath, [...existing, verifiedClaim]);
          } else {
            jp.set(claimsMap, fullPath, [verifiedClaim]);
          }
        }
      });
    });
  });
  return claimsMap;
};

const latestClaimFromMapForPath = (claimsMap, path) => {
  const claims = jp.get(claimsMap, path);
  if (claims) {
    return claims[claims.length - 1];
  }
  return null;
};

const contributingClaimsFromMapForData = (claimsMap, dataPath, data) => {
  const claims = [];
  traverse(data).forEach(function () {
    if (this.isLeaf) {
      const sourcePath =
        // json pointer is weird with "/" as the path
        this.path.length === 0
          ? dataPath
          : dataPath + "/" + this.path.join("/");
      const claimsToBeAdded = jp.get(claimsMap, sourcePath);
      claims.push(...claimsToBeAdded);
    }
  });
  const dedupedClaims = Array.from(new Set(claims));
  return dedupedClaims.sort((a, b) =>
    a.verification.time.localeCompare(b.verification.time)
  );
};

const latestClaimsFromMapForData = (claimsMap, dataPath, data) => {
  const claims = [];
  traverse(data).forEach(function () {
    if (this.isLeaf) {
      const sourcePath =
        // json pointer is weird with "/" as the path
        this.path.length === 0
          ? dataPath
          : dataPath + "/" + this.path.join("/");
      const claimsHistory = jp.get(claimsMap, sourcePath);
      if (claimsHistory) {
        claims.push(claimsHistory[claimsHistory.length - 1]);
      }
    }
  });
  const dedupedClaims = Array.from(new Set(claims));
  return dedupedClaims.sort((a, b) =>
    a.verification.time.localeCompare(b.verification.time)
  );
};

const filterVerifiedClaimsByPath = (verifiedClaims, pathToFilter) => {
  const filteredVerifiedClaims = verifiedClaims.filter((verifiedClaim) =>
    Object.keys(verifiedClaim.claims).includes(pathToFilter)
  );
  return filteredVerifiedClaims;
};

const filterClaimsByEvidenceType = (
  verifiedClaims,
  evidenceTypes = ["document", "electronic_record", "vouch"]
) => {
  const filteredVerifiedClaims = verifiedClaims.filter((verifiedClaim) => {
    const [firstEvidenceRecord] = verifiedClaim.verification.evidence;

    return evidenceTypes.includes(firstEvidenceRecord.type);
  });
  return filteredVerifiedClaims;
};

export default function useVerifiedClaimsUtils() {
  return {
    filterVerifiedClaimsByPath,
    filterClaimsByEvidenceType,
    getClaimsMap,
    latestClaimFromMapForPath,
    contributingClaimsFromMapForData,
    latestClaimsFromMapForData,
  };
}
