import useAuth from "@/composables/auth";

const impersonateUser = async (to, from, next) => {
  const { signUserInWithCustomToken } = useAuth();
  const { custom_session_token } = to.query;

  if (custom_session_token) {
    try {
      await signUserInWithCustomToken(custom_session_token);
    } catch (ex) {
      console.error(ex);
    }
  }

  next();
};

export default impersonateUser;
