<script setup>
import Icon from "./IconBase";
</script>

<script>
export default {
  name: "AdminIcon",
};
</script>

<template>
  <icon class="icon icon--admin">
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      class="icon__canvas"
      width="24"
      height="24"
    >
      <path
        class="icon__glyph icon__glyph--admin"
        d="M480-434.913q-61.261 0-102.891-41.348-41.631-41.348-41.631-102.609 0-61.26 41.631-102.891 41.63-41.63 102.891-41.63t102.891 41.63q41.631 41.631 41.631 102.891 0 61.261-41.631 102.609-41.63 41.348-102.891 41.348Zm0-88.478q23.739 0 39.609-15.87 15.869-15.869 15.869-39.609 0-23.739-15.869-39.891-15.87-16.152-39.609-16.152-23.739 0-39.609 16.152-15.869 16.152-15.869 39.891 0 23.74 15.869 39.609 15.87 15.87 39.609 15.87Zm0 44.521Zm0-308.217-233.217 87.174V-516q0 52.304 14.435 101.043 14.434 48.739 39.304 92.044 41.435-19.87 86.022-31.022Q431.13-365.087 480-365.087q48.304 0 93.456 11.152 45.153 11.152 86.022 31.022 25.435-43.305 39.587-92.044Q713.217-463.696 713.217-516v-183.913L480-787.087Zm0 510.478q-34.87 0-65.478 6.587-30.609 6.587-58.782 18.892 27.304 27.173 58.478 47.76 31.173 20.587 65.782 33.152 34.609-12.565 65.782-33.152 31.174-20.587 58.478-47.76-28.173-12.305-58.782-18.892-30.608-6.587-65.478-6.587Zm0 210.697q-8.696 0-16.957-1.283-8.261-1.282-16.522-3.848-138.956-46.13-222.348-173.848Q140.782-372.609 140.782-516v-183.913q0-33.478 19.022-60.261t49.935-38.61l233.217-87.173q17.957-6.696 37.044-6.696 19.087 0 37.044 6.696l233.217 87.173q30.913 11.827 49.935 38.61 19.022 26.783 19.022 60.261V-516q0 143.391-83.391 271.109-83.392 127.718-222.348 173.848-8.261 2.566-16.522 3.848-8.261 1.283-16.957 1.283Z"
      />
    </svg>
  </icon>
</template>
