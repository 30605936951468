import iso3ToIso2 from "@/enums/iso3-iso2";

/**
 * Undocumented, at least one phone number is required:
 * 'Mobile Phone Number' must not be empty.
 * 'Work Phone Number' must not be empty.
 * 'Home Phone Number' must not be empty.
 */
const formatClientRequest = (participant) => {
  const clientDetails = {
    title: participant?.name?.title || "",
    forenames: `${participant?.name?.firstName || ""} ${
      participant?.name?.middleName || ""
    }`.trim(),
    surname: participant?.name?.lastName || "",
  };

  // Don't provide an empty string, though it seems LMS requires at least one number (undocumented).
  if (participant?.phone) {
    clientDetails.mobileNumber = participant?.phone || "";
  }

  clientDetails.email = participant?.email || "";

  clientDetails.address = formatQuoteAddress(participant?.address || {});

  return clientDetails;
};

const formatQuoteAddress = (address) => {
  const postcode = address?.postcode || "";
  const [outwardCode = "", inwardCode = ""] = postcode.split(" ");
  let country = "";

  if (address.countryIso2) {
    country = address.countryIso2;
  }

  if (address.countryCode) {
    country = iso3ToIso2[address.countryCode];
  }

  return {
    houseNumber: address?.buildingNumber || "Not provided",
    streetName: address?.street || address?.line1 || "Not provided",
    districtName: address?.provinceName || "",
    postTown: address?.city || address?.line2 || "Not provided",
    county: address?.province || "",
    postcode1: outwardCode,
    postcode2: inwardCode,
    country,
  };
};

export default function useConveyancingQuoteLms() {
  return {
    formatQuoteAddress,
    formatClientRequest,
  };
}
