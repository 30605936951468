import useUserProfile from "@/composables/user/userProfile";
import useOffices from "@/composables/organisation/offices";

const requiresOrganisationOffices = async (to, from, next) => {
  const { organisationId } = useUserProfile();

  const { fetchOffices, hasFetchedOffices } = useOffices();

  if (!hasFetchedOffices) {
    await fetchOffices(organisationId.value);
  }
  next();
};

export default requiresOrganisationOffices;
