/**
 * TODO: Move all PDTF enums into this file.
 */
export const rentFrequency = Object.freeze({
  YEARLY: "Yearly",
  QUARTERLY: "Quarterly",
  MONTHLY: "Monthly",
  WEEKLY: "Weekly",
});

export const priceQualifier = Object.freeze({
  GUIDE_PRICE: "Guide price",
  FIXED_PRICE: "Fixed price",
  OFFERS_IN_EXCESS: "Offers in excess of",
  OFFERS_IN_REGION: "Offers in region of",
  SALE_BY_TENDER: "Sale by tender",
});

export const participantTitle = Object.freeze({
  MR: "Mr",
  MRS: "Mrs",
  MISS: "Miss",
  MS: "Ms",
  MX: "Mx",
  // Dr is in the schema but we are leaving it out of our UI.
  // Title is only here as it's a requirement for an LMS integration.
  // DR: "Dr",
});
