<script setup>
import ButtonGroup from "@/components/library/ButtonGroup";
import ButtonGroupItem from "@/components/library/ButtonGroupItem";
import CookieConsentManagement from "@/components/CookieConsentManagement";
import InputButton from "@/components/library/inputs/InputButton";
import useCookieConsent from "@/composables/cookieConsent";
import { ref } from "vue";

const { setEssentialConsent, setAllConsent, fetchSavedConsentMode } =
  useCookieConsent();

const showCookieManagement = ref(false);
const isVisible = ref(!fetchSavedConsentMode());

const onSetAllConsent = () => {
  setAllConsent();
  showCookieManagement.value = false;
  isVisible.value = false;
};

const onSetEssentialConsent = () => {
  setEssentialConsent();
  showCookieManagement.value = false;
  isVisible.value = false;
};

const onSavePreferences = () => {
  showCookieManagement.value = false;
  isVisible.value = false;
};

const onCancel = () => {
  showCookieManagement.value = false;
};
</script>

<template>
  <div class="cookie-consent" v-if="isVisible">
    <div class="cookie-consent__header">
      <h2 class="cookie-consent__heading">
        Cookies <span aria-label="cookie emoji">&#127850;</span>
      </h2>
    </div>
    <div class="cookie-consent__main">
      <p v-if="!showCookieManagement">
        <strong
          >We use some essential cookies to make this website work.</strong
        >
        We&rsquo;d like to use additional cookies to understand how you use
        Moverly, remember your settings, and improve our service to you.
        <InputButton
          class="button--anchor"
          isTertiary
          @click.prevent="showCookieManagement = true"
          >Manage cookies.</InputButton
        >
      </p>

      <CookieConsentManagement
        v-if="showCookieManagement"
        show-cancel-button
        @save="onSavePreferences"
        @cancel="onCancel"
      />
    </div>
    <div class="cookie-consent__footer" v-if="!showCookieManagement">
      <ButtonGroup>
        <ButtonGroupItem class="cookie-consent__button-group__item">
          <InputButton
            class="cookie-consent__button"
            @click.prevent="onSetAllConsent"
            >Accept all</InputButton
          ></ButtonGroupItem
        >
        <ButtonGroupItem class="cookie-consent__button-group__item">
          <InputButton
            class="cookie-consent__button button--outline"
            isSecondary
            @click.prevent="onSetEssentialConsent"
            >Just essential</InputButton
          ></ButtonGroupItem
        >
      </ButtonGroup>
    </div>
  </div>
</template>

<style lang="scss">
.cookie-consent {
  background: #fff;
  border-radius: radius();
  bottom: gutter();
  box-shadow: $layout-shadow;
  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;
  left: gutter();
  max-height: 100vh;
  max-width: 540px;
  overflow: auto;
  padding: stack(strong) gutter(strong) 0;
  position: fixed;
  width: calc(100% - gutter(strong));
  z-index: elevation("max");
}

.cookie-consent__button-group__item {
  width: calc(50% - gutter(strong));
}

.cookie-consent__button {
  width: 100%;
}

@include mq("medium-viewport") {
  .cookie-consent {
    bottom: gutter(strong);
    left: gutter(strong);
  }

  .cookie-consent__button-group__item {
    width: initial;
  }

  .cookie-consent__button {
    width: initial;
  }
}
</style>
