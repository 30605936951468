export default Object.freeze({
  BUYER: "Buyer",
  BUYER_CONVEYANCER: "Buyer's Conveyancer",
  ESTATE_AGENT: "Estate Agent",
  PROSPECTIVE_BUYER: "Prospective Buyer",
  SELLER: "Seller",
  SELLER_CONVEYANCER: "Seller's Conveyancer",
  SURVEYOR: "Surveyor",
  MORTGAGE_BROKER: "Mortgage Broker",
  LENDER: "Lender",
  BUYER_AGENT: "Buyer's Agent",
  LANDLORD: "Landlord",
  TENANT: "Tenant",
});
