/**
 * Back-end errors don't always return a specific message, so this provides an
 * alternative, user-friendly message.
 */
export default Object.freeze({
  "invite/410":
    "Your invite has expired. Please get in touch with your agent to get a new one.",
  "create-invite/409":
    "The email may already be in use. Please try an alternative.",
  "create-invite/422":
    "The information provided is not correct. Please check and try again.",
  "invite/404":
    "An invite could not be found. Please get in touch with your agent to request a new one.",
  "invite/401":
    "You've signed in with an email that isn't associated with your invite. Please sign out and try again using the email address that received the invite.",
  "invite/409": "The invite has already been accepted.",
  "invite/500":
    "There's been a problem accepting the invite. Please try again or contact us for assistance.",
  "add-executive-to-transaction/409":
    "You are already associated with this transaction.",
  "office/404": "The office could not be found.",
  "offices/404": "The offices for your organisation could not be found.",
  "office-settings/404":
    "The office settings for your office could not be found.",
  "office-settings/500":
    "There's been a problem updating the office settings. Please try again or contact us for assistance.",
  "office-user/422":
    "The offices could not be assigned to your team. The offices supplied were not in an expected format.Please try again.",
  "organisation-invite/409":
    "The email provided is already in use. Please try an alternative.",
  "organisation-invite/401":
    "You do not have permission to perform this action.",
  "organisation-invite/403":
    "You do not have permission to perform this action.",
});
