<script setup>
import ButtonGroup from "@/components/library/ButtonGroup";
import ButtonGroupItem from "@/components/library/ButtonGroupItem";
import DisclosureIcon from "@/components/library/icons/DisclosureIcon";
import InputButton from "@/components/library/inputs/InputButton";
import InputToggle from "@/components/library/inputs/InputToggle";
import useCookieConsent from "@/composables/cookieConsent";
import SuccessIcon from "@/components/library/icons/SuccessIcon";
import { watchEffect, ref, reactive, onMounted } from "vue";

const emit = defineEmits(["save", "cancel"]);

defineProps({
  showCancelButton: {
    type: Boolean,
    default: false,
  },
});

const { setConsent, fetchSavedConsentMode, savedPreferences } =
  useCookieConsent();

const hasSaved = ref(false);
let resetStateTimer = null;
const resetStateInSeconds = 1.5;

const consentForm = reactive({
  marketing: true,
  analytics: true,
  preferences: true,
  necessary: true,
});

const consentManagement = reactive({
  necessary: {
    isActive: false,
  },
  preferences: {
    isActive: false,
  },
  analytics: {
    isActive: false,
  },
  marketing: {
    isActive: false,
  },
});

const onSavePreferences = () => {
  setConsent(consentForm);
  hasSaved.value = true;
  emit("save", false);
};

const onCancel = () => {
  emit("cancel", false);
};

onMounted(() => {
  fetchSavedConsentMode();
  consentForm.marketing = savedPreferences.marketing;
  consentForm.analytics = savedPreferences.analytics;
  consentForm.preferences = savedPreferences.preferences;
  consentForm.necessary = savedPreferences.necessary;
});

watchEffect(() => {
  if (hasSaved.value) {
    clearTimeout(resetStateTimer);
    resetStateTimer = setTimeout(
      () => (hasSaved.value = false),
      resetStateInSeconds * 1000
    );
  } else {
    clearTimeout(resetStateTimer);
    resetStateTimer = null;
  }
});
</script>

<template>
  <p>
    Blocking some types of cookies may negatively impact your experience on the
    site and limit the services we are able to provide.
  </p>
  <div class="cookie-consent-management">
    <div class="cookie-consent-management-category">
      <div class="cookie-consent-management-category__header">
        <button
          :aria-expanded="consentManagement.necessary.isActive"
          @click="
            consentManagement.necessary.isActive =
              !consentManagement.necessary.isActive
          "
          class="cookie-consent-management-category__button"
        >
          <DisclosureIcon
            class="cookie-consent-management-category__signifier"
            :is-active="consentManagement.necessary.isActive"
          />
          Necessary
        </button>
        <span
          class="cookie-consent-management-category__signifier cookie-consent-management-category__signifier--necessary"
          >Always active</span
        >
      </div>

      <div
        class="cookie-consent-management-category__main"
        v-if="consentManagement.necessary.isActive"
      >
        <p>
          These cookies are essential for the website to work and cannot be
          turned off. They are usually set when you do things like set your
          privacy preferences, log in, or fill in forms.
        </p>
      </div>
    </div>

    <div class="cookie-consent-management-category">
      <div class="cookie-consent-management-category__header">
        <button
          :aria-expanded="consentManagement.preferences.isActive"
          @click="
            consentManagement.preferences.isActive =
              !consentManagement.preferences.isActive
          "
          class="cookie-consent-management-category__button"
        >
          <DisclosureIcon
            class="cookie-consent-management-category__signifier"
            :is-active="consentManagement.preferences.isActive"
          />
          Preferences
        </button>
        <InputToggle
          v-model="consentForm.preferences"
          class="cookie-consent-management-category__checkbox"
          label="Necessary cookies"
        />
      </div>

      <div
        class="cookie-consent-management-category__main"
        v-if="consentManagement.preferences.isActive"
      >
        <p>
          We use a set of cookies that are optional for the website to function.
          They are usually only set in response to information provided to the
          website to personalise and optimise your experience, as well as
          remember your chat history.
        </p>
      </div>
    </div>
    <div class="cookie-consent-management-category">
      <div class="cookie-consent-management-category__header">
        <button
          :aria-expanded="consentManagement.analytics.isActive"
          @click="
            consentManagement.analytics.isActive =
              !consentManagement.analytics.isActive
          "
          class="cookie-consent-management-category__button"
        >
          <DisclosureIcon
            class="cookie-consent-management-category__signifier"
            :is-active="consentManagement.analytics.isActive"
          />
          Analytics
        </button>
        <InputToggle
          class="cookie-consent-management-category__checkbox"
          v-model="consentForm.analytics"
          label="Enable analytic cookies"
        />
      </div>

      <div
        class="cookie-consent-management-category__main"
        v-if="consentManagement.analytics.isActive"
      >
        <p>
          These cookies help us understand how visitors interact with the
          website. We might use a set of cookies to gather information and
          report on site usage. Besides reporting site usage statistics, the
          collected data may also be used with some of the advertising cookies
          mentioned to show more relevant ads across the web and to measure
          interactions with the ads we display.
        </p>
      </div>
    </div>
    <div class="cookie-consent-management-category">
      <div class="cookie-consent-management-category__header">
        <button
          :aria-expanded="consentManagement.marketing.isActive"
          @click="
            consentManagement.marketing.isActive =
              !consentManagement.marketing.isActive
          "
          class="cookie-consent-management-category__button"
        >
          <DisclosureIcon
            class="cookie-consent-management-category__signifier"
            :is-active="consentManagement.marketing.isActive"
          />
          Advertisement
        </button>
        <InputToggle
          class="cookie-consent-management-category__checkbox"
          v-model="consentForm.marketing"
          label="Enable marketing cookies"
        />
      </div>

      <div
        class="cookie-consent-management-category__main"
        v-if="consentManagement.marketing.isActive"
      >
        <p>
          We use cookies to make our ads more interesting and useful for site
          visitors. Common uses of cookies include choosing ads that are
          relevant to the user, improving reports on how well ad campaigns are
          doing, and avoiding showing ads the user has already seen.
        </p>
      </div>
    </div>
  </div>

  <ButtonGroup>
    <ButtonGroupItem class="cookie-consent__button-group__item">
      <InputButton
        class="cookie-consent__button"
        @click.prevent="onSavePreferences"
        ><template #icon-prefix><SuccessIcon v-if="hasSaved" /></template
        >{{ hasSaved ? `Saved` : `Save` }}</InputButton
      ></ButtonGroupItem
    >
    <ButtonGroupItem
      class="cookie-consent__button-group__item"
      v-if="showCancelButton"
    >
      <InputButton
        class="cookie-consent__button"
        isTertiary
        @click.prevent="onCancel"
        >Cancel</InputButton
      ></ButtonGroupItem
    >
  </ButtonGroup>
</template>

<style lang="scss">
.cookie-consent-management {
  margin: 0 0 stack(strongest);
}

.cookie-consent-management-category {
  border: solid 2px $color-stroke;
  border-width: 2px 0;

  & + .cookie-consent-management-category {
    border-width: 0 0 2px;
  }
}

.cookie-consent-management-category__header {
  padding: stack() 0;
  align-items: center;
  display: flex;
}

.cookie-consent-management-category__button {
  all: inherit;
  margin: 0;
  font-weight: 500;
  cursor: pointer;
  flex: 1;
}

.cookie-consent-management-category__checkbox {
  .input-toggle__option__signifier {
    margin-right: 0;
  }

  .input-toggle__option__label {
    @include visually-hidden();
  }
}

.cookie-consent-management-category__main {
  padding: 0 gutter() 0 0;
}
</style>
