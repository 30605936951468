import { computed } from "vue";

const generateInputId = () => {
  const id = Math.random().toString(36).substr(2, 5);

  return id;
};

const useHasDescription = ({ props, slots }) => {
  return computed(() => {
    return slots["description"] || props.description;
  });
};

const useDescribedByIds = ({
  props,
  slots,
  validationMessageIds,
  descriptionId,
}) => {
  return computed(() => {
    const describedByIds = [];

    if (slots["description"] || props.description) {
      describedByIds.push(descriptionId);
    }

    return describedByIds.concat(validationMessageIds.value);
  });
};

const useInputValue = (props, emit) => {
  return computed({
    get: () => props.modelValue,
    set: (value) => {
      emit("update:modelValue", value);
    },
  });
};

const debounce = function (fn, delay) {
  var timeoutId = null;

  return function () {
    clearTimeout(timeoutId);

    var args = arguments;
    var that = this;

    timeoutId = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
};

const useValidationFails = ({ props }) => {
  return computed(() => !!props.validation.$errors.length);
};

const useValidationMessageIds = ({ props }) => {
  return computed(() => {
    const errors = props.validation.$errors;

    const messageIds = errors.reduce((messageIdCollection, error) => {
      const { $property, $validator } = error;

      const messageId = `validation-${$property}-${$validator}`;

      return [...messageIdCollection, messageId];
    }, []);
    return messageIds.join(" ");
  });
};

export default function useInputs() {
  return {
    useDescribedByIds,
    useValidationFails,
    useHasDescription,
    debounce,
    generateInputId,
    useValidationMessageIds,
    useInputValue,
  };
}
