import requiresAuth from "../guards/requiresAuth";

export default [
  {
    path: "setup/payment",
    beforeEnter: requiresAuth,
    name: "setup.payment",
    component: () =>
      import(
        /* webpackChunkName: "upfront-searches" */ "../../views/seller-payment/SellerPayByCard.vue"
      ),
    meta: {
      title: "Pay securely",
    },
  },
  {
    path: "setup/payment/authorisation",
    beforeEnter: requiresAuth,
    name: "setup.payment.authorisation",
    component: () =>
      import(
        /* webpackChunkName: "upfront-searches" */ "../../views/seller-payment/3DSecure.vue"
      ),
    meta: {
      title: "Authorisation",
    },
  },
  {
    path: "setup/payment/confirmation",
    beforeEnter: requiresAuth,
    name: "setup.payment.confirmation",
    component: () =>
      import(
        /* webpackChunkName: "upfront-searches" */ "../../views/seller-payment/SellerPaymentConfirmation.vue"
      ),
    meta: {
      title: "Confirmation",
    },
  },
];
