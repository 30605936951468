export const removeMetaTags = () => {
  if (!document?.querySelector || !document?.head) return;

  const generatedTags = document.querySelectorAll(`head meta[data-generated]`);

  if (generatedTags.length === 0) return;

  generatedTags.forEach((element) => {
    element.parentNode.removeChild(element);
  });
};

export const createOrUpdateMetaTag = (metaTagAttributes) => {
  if (!document?.querySelector || !document?.head) return;

  const { name } = metaTagAttributes;
  let metaTag = document.querySelector(`head meta[name="${name}"]`);

  if (!metaTag) {
    metaTag = document.createElement("meta");
    metaTag.setAttribute("data-generated", true);
    document.head.appendChild(metaTag);
  }

  Object.entries(metaTagAttributes).forEach(
    ([attributeName, attributeValue]) => {
      metaTag.setAttribute(attributeName, attributeValue);
    }
  );
};

export const updateMetaTags = (newMetaTags) => {
  removeMetaTags();
  newMetaTags.forEach(createOrUpdateMetaTag);
};
