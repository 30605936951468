import useUserTasks from "@/composables/user/userTasks";
const {
  LISTING_QUESTIONS_USER_STEP,
  LETTINGS_LISTING_QUESTIONS_USER_STEP,
  MATERIAL_INFO_QUESTIONS_USER_STEP,
  LEGAL_INFO_QUESTIONS_USER_STEP,
  PROPERTY_QUESTIONS_USER_STEP,
  LEASEHOLD_QUESTIONS_USER_STEP,
  FITTINGS_AND_CONTENTS_QUESTIONS_USER_STEP,
  SEF_LISTING_QUESTIONS_USER_STEP,
} = useUserTasks();

export default [
  {
    path: "manage/propertyPack/listing/start",
    name: "property-pack.listing.start",
    component: () =>
      import(
        /* webpackChunkName: "user-tasks"" */ "../../views/property/baspi/Start.vue"
      ),
    props: () => {
      return {
        taskCategory: "listing",
        userStepName: LISTING_QUESTIONS_USER_STEP,
      };
    },
    meta: {
      title: "Listing questions",
    },
  },
  {
    path: "manage/propertyPack/lettings-listing/start",
    name: "property-pack.lettings-listing.start",
    component: () =>
      import(
        /* webpackChunkName: "user-tasks"" */ "../../views/property/baspi/Start.vue"
      ),
    props: () => {
      return {
        taskCategory: "lettings-listing",
        userStepName: LETTINGS_LISTING_QUESTIONS_USER_STEP,
      };
    },
    meta: {
      title: "Listing questions",
    },
  },
  {
    path: "manage/propertyPack/material-info/start",
    name: "property-pack.material-info.start",
    component: () =>
      import(
        /* webpackChunkName: "user-tasks"" */ "../../views/property/baspi/Start.vue"
      ),
    props: () => {
      return {
        taskCategory: "material-info",
        userStepName: MATERIAL_INFO_QUESTIONS_USER_STEP,
      };
    },
    meta: {
      title: "Property questions",
    },
  },
  {
    path: "manage/propertyPack/legal-info/start",
    name: "property-pack.legal-info.start",
    component: () =>
      import(
        /* webpackChunkName: "user-tasks"" */ "../../views/property/baspi/Start.vue"
      ),
    props: () => {
      return {
        taskCategory: "legal-info",
        userStepName: LEGAL_INFO_QUESTIONS_USER_STEP,
      };
    },
    meta: {
      title: "Start legal questions",
    },
  },
  {
    path: "manage/propertyPack/property-questions/start",
    name: "property-pack.property-questions.start",
    component: () =>
      import(
        /* webpackChunkName: "user-tasks"" */ "../../views/property/baspi/Start.vue"
      ),
    props: () => {
      return {
        taskCategory: "property-questions",
        userStepName: PROPERTY_QUESTIONS_USER_STEP,
      };
    },
    meta: {
      title: "Start property information questions",
    },
  },
  {
    path: "manage/propertyPack/leasehold-questions/start",
    name: "property-pack.leasehold-questions.start",
    component: () =>
      import(
        /* webpackChunkName: "user-tasks"" */ "../../views/property/baspi/Start.vue"
      ),
    props: () => {
      return {
        taskCategory: "leasehold-questions",
        userStepName: LEASEHOLD_QUESTIONS_USER_STEP,
      };
    },
    meta: {
      title: "Start leasehold information questions",
    },
  },
  {
    path: "manage/propertyPack/fittings-and-contents/start",
    name: "property-pack.fittings-and-contents.start",
    component: () =>
      import(
        /* webpackChunkName: "user-tasks"" */ "../../views/property/baspi/Start.vue"
      ),
    props: () => {
      return {
        taskCategory: "fittings-and-contents",
        userStepName: FITTINGS_AND_CONTENTS_QUESTIONS_USER_STEP,
      };
    },
    meta: {
      title: "Start fittings and contents questions",
    },
  },
  {
    path: "manage/propertyPack/fittings-and-contents/complete",
    name: "property-pack.fittings-and-contents.finish",
    component: () =>
      import(
        /* webpackChunkName: "user-tasks"" */ "../../views/property/baspi/Finish.vue"
      ),
    props: () => {
      return {
        taskCategory: "fittings-and-contents",
        userStepName: FITTINGS_AND_CONTENTS_QUESTIONS_USER_STEP,
      };
    },
    meta: {
      title: "Completed fittings and contents questions",
    },
  },
  {
    path: "manage/propertyPack/leasehold-questions/complete",
    name: "property-pack.leasehold-questions.finish",
    component: () =>
      import(
        /* webpackChunkName: "user-tasks"" */ "../../views/property/baspi/Finish.vue"
      ),
    props: () => {
      return {
        taskCategory: "leasehold-questions",
        userStepName: LEASEHOLD_QUESTIONS_USER_STEP,
      };
    },
    meta: {
      title: "Completed leasehold questions",
    },
  },
  {
    path: "manage/propertyPack/property-questions/complete",
    name: "property-pack.property-questions.finish",
    component: () =>
      import(
        /* webpackChunkName: "user-tasks"" */ "../../views/property/baspi/Finish.vue"
      ),
    props: () => {
      return {
        taskCategory: "property-questions",
        userStepName: PROPERTY_QUESTIONS_USER_STEP,
      };
    },
    meta: {
      title: "Completed property information questions",
    },
  },
  {
    path: "manage/propertyPack/legal-info/complete",
    name: "property-pack.legal-info.finish",
    component: () =>
      import(
        /* webpackChunkName: "user-tasks"" */ "../../views/property/baspi/Finish.vue"
      ),
    props: () => {
      return {
        taskCategory: "legal-info",
        userStepName: LEGAL_INFO_QUESTIONS_USER_STEP,
      };
    },
    meta: {
      title: "Completed legal questions",
    },
  },
  {
    path: "manage/propertyPack/material-info/complete",
    name: "property-pack.material-info.finish",
    component: () =>
      import(
        /* webpackChunkName: "user-tasks"" */ "../../views/property/baspi/Finish.vue"
      ),
    props: () => {
      return {
        taskCategory: "material-info",
        userStepName: MATERIAL_INFO_QUESTIONS_USER_STEP,
      };
    },
    meta: {
      title: "Completed property questions",
    },
  },
  {
    path: "manage/propertyPack/listing/complete",
    name: "property-pack.listing.finish",
    component: () =>
      import(
        /* webpackChunkName: "user-tasks"" */ "../../views/property/baspi/Finish.vue"
      ),
    props: () => {
      return {
        taskCategory: "listing",
        userStepName: LISTING_QUESTIONS_USER_STEP,
      };
    },
    meta: {
      title: "Completed listing questions",
    },
  },
  {
    path: "manage/propertyPack/listing/complete",
    name: "property-pack.sef-listing.finish",
    component: () =>
      import(
        /* webpackChunkName: "user-tasks"" */ "../../views/property/baspi/Finish.vue"
      ),
    props: () => {
      return {
        taskCategory: "sef-listing",
        userStepName: SEF_LISTING_QUESTIONS_USER_STEP,
      };
    },
    meta: {
      title: "Completed listing questions",
    },
  },
  {
    path: "manage/propertyPack/lettings-listing/complete",
    name: "property-pack.lettings-listing.finish",
    component: () =>
      import(
        /* webpackChunkName: "user-tasks"" */ "../../views/property/baspi/Finish.vue"
      ),
    props: () => {
      return {
        taskCategory: "lettings-listing",
        userStepName: LETTINGS_LISTING_QUESTIONS_USER_STEP,
      };
    },
    meta: {
      title: "Completed lettings listing questions",
    },
  },
  {
    path: "manage/seller-signatures/propertyPack/saleReadyDeclarations/sellerSignatures",
    props: true,
    name: "property-pack.seller-signatures",
    component: () =>
      import(
        /* webpackChunkName: "user-tasks"" */ "../../views/property/SaleReadyDocumentSigning.vue"
      ),
  },
  {
    path: "manage/:category/:pdtfPath+",
    name: "form.task",
    beforeEnter: (to, from, next) => {
      if (to.params.category === "seller-signatures") {
        return next({
          name: "property-pack.seller-signatures",
          params: to.params,
        });
      }
      next();
    },
    component: () =>
      import(
        /* webpackChunkName: "user-tasks"" */ "../../views/property/baspi/TasksItem.vue"
      ),
  },
  /**
   * TODO: Is this still needed?
   * A redirect as the declaration is based on the same TaskItem component.
   */
  {
    path: "manage/propertyPack/sale-ready/start",
    name: "property-pack.sale-ready.start",
    redirect: (to) => ({ name: "property.view", params: to.params }),
  },
  /**
   * Redirect back to the dashboard for now.
   */
  {
    path: "manage/propertyPack/sale-ready/finish",
    name: "property-pack.sale-ready.finish",
    redirect: (to) => ({ name: "property.view", params: to.params }),
  },
];
