export const reapitPropertyStyle = Object.freeze({
  BASEMENT: "Basement",
  BUNGALOW: "Bungalow",
  DETACHED: "Detached",
  DUPLEX: "Duplex",
  ENDTERRACE: "End of Terrace",
  FIRSTFLOOR: "First Floor",
  GROUNDFLOOR: "Ground Floor",
  LINKDETACHED: "Link-Detached",
  LOWERGROUNDFLOOR: "Lower Ground Floor",
  MEWS: "Mews",
  PENTHOUSE: "Penthouse",
  SEMIDETACHED: "Semi-Detached",
  STEADING: "Steading",
  STUDIO: "Studio",
  TERRACED: "Terraced",
  UPPERFLOOR: "Upper Floor",
  UPPERFLOORWITHLIFT: "Upper Floor with Lift",
});

export const reapitTenureType = Object.freeze({
  FREEHOLD: "freehold",
  LEASEHOLD: "leasehold",
  SHARE_OF_FREEHOLD: "shareOfFreehold",
  COMMONHOLD: "commonhold",
  TBA: "tba",
});

// Some of these types don't seem to make sense, but this is the full range of values.
export const reapitDisposal = Object.freeze({
  AUCTION: "auction",
  CONFIDENTIAL: "confidential",
  TENDER: "tender",
  OFFERS_INVITED: "offersInvited",
  PRIVATE_TREATY: "privateTreaty",
  SHARED_OWNERSHIP: "sharedOwnership",
});

export const reapitMediaType = Object.freeze({
  PHOTOGRAPH: "photograph",
  MAP: "map",
  FLOORPLAN: "floorPlan",
  EPC: "epc",
});

export const reapitRentFrequency = Object.freeze({
  WEEKLY: "weekly",
  MONTHLY: "monthly",
  ANNUALLY: "annually",
});

export const reapitDepositType = Object.freeze({
  WEEKS: "weeks",
  MONTHS: "months",
  FIXED: "fixed",
});

export const reapitLettingStatus = Object.freeze({
  VALUATION: "valuation",
  TO_LET: "toLet",
  TO_LET_UNAVAILABLE: "toLetUnavailable",
  UNDER_OFFER: "underOffer",
  UNDER_OFFER_UNAVAILABLE: "underOfferUnavailable",
  ARRANGING_TENANCY_UNAVAILABLE: "arrangingTenancyUnavailable",
  ARRANGING_TENANCY: "arrangingTenancy",
  TENANCY_CURRENT_UNAVAILABLE: "tenancyCurrentUnavailable",
  TENANCY_CURRENT: "tenancyCurrent",
  TENANCY_FINISHED: "tenancyFinished",
  TENANCY_CANCELLED: "tenancyCancelled",
  SOLD: "sold",
  LET_BY_OTHER_AGENT: "letByOtherAgent",
  LET_PRIVATELY: "letPrivately",
  PROVISIONAL: "provisional",
  WITHDRAWN: "withdrawn",
});

export const reapitPriceQualifier = Object.freeze({
  ASKING_PRICE: "askingPrice",
  PRICE_ON_APPLICATION: "priceOnApplication",
  GUIDE_PRICE: "guidePrice",
  OFFERS_IN_REGION: "offersInRegion",
  OFFERS_OVER: "offersOver",
  OFFERS_IN_EXCESS: "offersInExcess",
  FIXED_PRICE: "fixedPrice",
  PRICE_REDUCED_TO: "priceReducedTo",
});

export const reapitMarketingMode = Object.freeze({
  SELLING: "selling",
  LETTING: "letting",
  SELLING_AND_LETTING: "sellingAndLetting",
});
