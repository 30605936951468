import useOrganisationSettings from "@/composables/organisation/organisationSettings";

const redirectIfUsingConfiguredProducts = async (to, from, next) => {
  const { hasConfiguredUpgradeProducts } = useOrganisationSettings();

  if (hasConfiguredUpgradeProducts.value) {
    next({ ...to, name: "agent.transaction.upgrade.review" });
    return;
  }

  next();
};

export default redirectIfUsingConfiguredProducts;
