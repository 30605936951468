<script setup>
defineProps({
  imageUrl: {
    type: String,
    default: "",
  },
  name: {
    type: String,
    default: "",
  },
});
</script>

<script>
export default {
  name: "AvatarComponent",
};
</script>

<template>
  <span class="avatar">
    <span class="avatar__image">
      <img :src="imageUrl" :alt="name" />
    </span>
  </span>
</template>

<style lang="scss">
.avatar {
  display: inline-flex;
}

.avatar__image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  overflow: hidden;
  border: solid 2px #fff;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
