const TITLE_SEPARATOR = " - ";

const formatTitle = (titleBreadcrumbs = [], appName = "") => {
  const newBreadcrumbs = [...titleBreadcrumbs];

  if (appName) {
    newBreadcrumbs.push(appName);
  }

  const formattedPageTitle = newBreadcrumbs.join(TITLE_SEPARATOR);

  return formattedPageTitle;
};

const setTitle = (titleToSet) => {
  document.title = titleToSet;
};

const updateTitle = (
  titleBreadcrumbsToUpdate = [],
  numberOfCrumbsToUpdate = 1
) => {
  const existingTitle = document.title;
  const titleBreadcrumbs = existingTitle.split(TITLE_SEPARATOR);
  titleBreadcrumbs.splice(
    0,
    numberOfCrumbsToUpdate,
    ...titleBreadcrumbsToUpdate
  );
  document.title = titleBreadcrumbs.join(TITLE_SEPARATOR);
};

export default function useMeta() {
  return {
    formatTitle,
    updateTitle,
    setTitle,
  };
}
