import useUserProfile from "@/composables/user/userProfile";
import useOrganisationUsers from "@/composables/organisation/organisationUsers";

const requiresOrganisationUsers = async (to, from, next) => {
  const { organisationId } = useUserProfile();
  const { fetchOrganisationUsers, hasFetchedOrganisationUsers } =
    useOrganisationUsers();

  if (!hasFetchedOrganisationUsers) {
    await fetchOrganisationUsers(organisationId.value);
  }
  next();
};

export default requiresOrganisationUsers;
