export default Object.freeze({
  listing: "nts2023",
  "lettings-listing": "ntsl2023",
  "material-info": "baspiV4",
  "legal-info": "baspiV4",
  "property-questions": "ta6ed4",
  "leasehold-questions": "ta7ed3",
  "fittings-and-contents": "ta10ed3",
  "sale-ready": "sr24",
});
