import Honeybadger from "@honeybadger-io/js";
import disposal from "@/enums/disposal";
import useAddressSearch from "@/composables/address/addressSearch";
import useConveyancingQuoteLms from "@/composables/lms/conveyancingQuoteLms";
import { FIREBASE_ENABLE_LIMITED_USE_APP_CHECK_TOKENS } from "@/config";
import { checkSeverity } from "@/enums/checks";
import { functions } from "@/firebase";
import { httpsCallable } from "firebase/functions";
import { ref, watch, computed } from "vue";
import { usePDTF } from "@/composables/pdtf";
import useChecks from "@/composables/flags/checks";

const { formatQuoteAddress, formatClientRequest } = useConveyancingQuoteLms();
const { filterFlagsByName } = useChecks();
const { getStateByPath, pdtfState } = usePDTF();
const { findAddressByUprn } = useAddressSearch();
const saleConveyancingQuotes = ref([]);
const isFetchingSaleQuote = ref(false);
const isInstructingSaleQuote = ref(false);
const quoteGroupId = ref("");
const instructionData = ref(null);
const caseId = ref("");

const resetQuote = () => {
  saleConveyancingQuotes.value = [];
  quoteGroupId.value = "";
  instructionData.value = null;
  caseId.value = "";
};

watch(
  () => pdtfState.value,
  (newPdtfState, oldPdtfState) => {
    if (newPdtfState?.transactionId !== oldPdtfState?.transactionId) {
      resetQuote();
    }

    let saleCaseId = getStateByPath("/externalIds/LMS/sale/caseId", "");

    if (!saleCaseId) {
      // Temporary fallback for regression during UAT.
      saleCaseId = getStateByPath("/externalIds/LMS/caseId", "");
    }

    caseId.value = saleCaseId;
  },
  { immediate: true }
);

const formatInstructionCaseNotes = (transactionId) => {
  const notes = [];

  if (transactionId) {
    notes.push(`Moverly transaction ${transactionId}`);
  }
  return notes.join("\n");
};

const formatInstructionRequest = async (
  transactionId = "",
  propertyToInstruct = {},
  participants = [],
  identityVerification = []
) => {
  const address = await findAddressByUprn(propertyToInstruct?.uprn);
  const propertyAddress = formatQuoteAddress(address);
  const instructionRequest = {
    propertyAddress,
    identityVerification,
  };
  instructionRequest.clients = participants.map(formatClientRequest);
  instructionRequest.caseNotes = formatInstructionCaseNotes(transactionId);

  return instructionRequest;
};

/**
 * There seems to be some nuance in the way the quote request is formatted.
 * For example, certain combinations of booleans are not allowed.
 */
const formatQuoteRequest = async (data) => {
  const address = await findAddressByUprn(data.uprn);
  const propertyAddress = formatQuoteAddress(address);
  const request = {
    salePrice: data.salePrice,
    ltdCompanySale: data.ltdCompanySale,
    numberOfCurrentOwners: data.numberOfCurrentOwners,
    saleAtUndervalue: data.saleAtUndervalue,
    helpToBuyEquityLoan: data.helpToBuyEquityLoan,
    multipleOccupancy: data.multipleOccupancy,
    studentProperty: data.studentProperty,
    firstRegistration: data.firstRegistration,
    outStandingMortgage: data.outStandingMortgage,
    jurisdiction: address?.homeNation || "",
    propertyAddress,
    customerDetails: {
      correspondenceAddress: propertyAddress,
    },
  };

  if (data.outStandingMortgage) {
    request.existingLenderId = data?.existingLenderId;
  }

  /**
   * The documentation appears incorrect - numberOfExpats must be 1-4 if that value is present. If
   * there are no expats, do NOT include that key in the request.
   */
  if (!isNaN(data?.numberOfExpats) && data.numberOfExpats > 0) {
    request.numberOfExpats = data.numberOfExpats;
  }

  if (["Share of Freehold", "Shared Ownership"].includes(data.tenure)) {
    request.tenure = "Leasehold";
    request.sharedOwnership = true;
  } else {
    request.tenure = data.tenure;
    request.sharedOwnership = false;
  }

  request.auction = [
    disposal.AUCTION,
    disposal.MODERN_METHOD_OF_AUCTION,
  ].includes(data?.disposal);

  return request;
};

const fetchSaleQuote = async (transactionId) => {
  isFetchingSaleQuote.value = true;
  const getSaleQuotes = httpsCallable(functions, "collectors-getSaleQuotes", {
    limitedUseAppCheckTokens: FIREBASE_ENABLE_LIMITED_USE_APP_CHECK_TOKENS,
  });

  try {
    const sellerQuote = await getSaleQuotes({
      transactionId,
    });
    const conveyancyQuotes = sellerQuote?.data?.conveyancyQuotes || [];
    saleConveyancingQuotes.value = conveyancyQuotes;
    quoteGroupId.value = sellerQuote?.data?.id;

    return sellerQuote.data;
  } catch (ex) {
    Honeybadger.notify(ex, {
      name: "conveyancingSaleQuoteLms.js",
      message: `Failed get an LMS conveyancing quote for transaction ${transactionId}`,
      params: {
        transactionId,
      },
    });
    throw ex;
  } finally {
    isFetchingSaleQuote.value = false;
  }
};

const instructSaleQuote = async ({
  transactionId,
  quoteGroupId,
  quoteId,
  lawFirmId,
  instruction,
}) => {
  isInstructingSaleQuote.value = true;
  const instructQuote = httpsCallable(functions, "collectors-instructQuote", {
    limitedUseAppCheckTokens: FIREBASE_ENABLE_LIMITED_USE_APP_CHECK_TOKENS,
  });

  try {
    const sellerQuote = await instructQuote({
      transactionId,
      quoteGroupId,
      quoteId,
      lawFirmId,
      ...instruction,
    });
    instructionData.value = sellerQuote.data;

    return instructionData.value;
  } catch (ex) {
    Honeybadger.notify(ex, {
      name: "conveyancingSaleQuoteLms.js",
      message: `Failed get an instruct an LMS conveyancing quote for transaction ${transactionId}`,
      params: {
        transactionId,
        quoteId,
      },
    });
    throw ex;
  } finally {
    isInstructingSaleQuote.value = false;
  }
};

/**
 * A case Id must imply that the conveyancer has been instructed.
 */
const hasInstructedConveyancer = computed(() => !!caseId.value);

const validateInstructionData = ({ participants, numberOfSellers, checks }) => {
  const errors = [];

  if (participants.length === 0) {
    errors.push(
      "There are no sellers to instruct. They must accept an invite to the transaction and provide their personal details in order to proceed."
    );
  }

  const numberOfSellersIsValid = !isNaN(numberOfSellers) && numberOfSellers > 0;
  if (!numberOfSellersIsValid) {
    errors.push("Confirm the number of sellers.");
  }

  if (
    participants.length > 0 &&
    numberOfSellersIsValid &&
    participants.length !== numberOfSellers
  ) {
    const pluralisedSellers =
      numberOfSellers === 1 ? "1 seller" : `${numberOfSellers} sellers`;
    const pluralisedParticipants =
      participants.length === 1
        ? "is 1 seller"
        : `are ${participants.length} sellers`;
    const comparativeDegree =
      numberOfSellers < participants.length ? "more" : "fewer";

    errors.push(
      `There are ${comparativeDegree} sellers than the number declared. We are expecting ${pluralisedSellers}, but there ${pluralisedParticipants} present in the transaction. Please update the number of sellers you expect or contact us for assistance.`
    );
  }

  const isParticipantPersonalDetailsComplete = participants.every(
    ({ index }) => {
      const participantPersonalDetailFlag = filterFlagsByName(
        `participantPersonalDetails-${index}`,
        checks,
        true
      );

      return participantPersonalDetailFlag?.severity === checkSeverity.SUCCESS;
    }
  );

  if (!isParticipantPersonalDetailsComplete) {
    if (participants.length === 1) {
      errors.push(
        "The name and address for the seller is incomplete. They must complete their personal details in order to proceed."
      );
    } else {
      errors.push(
        "The names and addresses for the sellers are incomplete. They must complete their personal details in order to proceed."
      );
    }
  }

  return errors;
};

export default function useConveyancingSaleQuoteLms() {
  return {
    caseId,
    fetchSaleQuote,
    formatInstructionCaseNotes,
    formatInstructionRequest,
    formatQuoteRequest,
    hasInstructedConveyancer,
    instructSaleQuote,
    instructionData,
    isFetchingSaleQuote,
    isInstructingSaleQuote,
    quoteGroupId,
    resetQuote,
    saleConveyancingQuotes,
    validateInstructionData,
  };
}
