import Honeybadger from "@honeybadger-io/js";
import useChecks from "@/composables/flags/checks";
import useConveyancingSaleQuoteLms from "@/composables/lms/conveyancingSaleQuoteLms";
import { FIREBASE_ENABLE_LIMITED_USE_APP_CHECK_TOKENS } from "@/config";
import { checkSeverity } from "@/enums/checks";
import { functions } from "@/firebase";
import { httpsCallable } from "firebase/functions";
import { ref, computed } from "vue";

const { filterFlagsByName, checks } = useChecks();
const { hasInstructedConveyancer } = useConveyancingSaleQuoteLms();

// Restriced to an LMS instruction. Having a conveyancer doesn't mean they've been instructed.
// An example would be choosing a conveyancer from the non-Sale-Ready LMS panel compared
// to the vendor choosing a CHC conveyancer, which the agent will actually instruct.
const isInstructed = computed(() => hasInstructedConveyancer.value);

/**
 * Maybe not the best place for this property, but close enough.
 */

const isSaleReady = computed(() => {
  const saleReadyFlag = filterFlagsByName(
    "contractReadyCompletion",
    checks.value,
    true
  );

  return saleReadyFlag?.severity === checkSeverity.SUCCESS;
});

const hasSellerConveyancer = computed(() => {
  const legacyConveyancerFlag = filterFlagsByName(
    "participantSellerConveyancer",
    checks.value,
    true
  );

  const sellerSelectConveyancerFlag = filterFlagsByName(
    "sellerSelectConveyancer",
    checks.value,
    true
  );

  return (
    sellerSelectConveyancerFlag?.severity === checkSeverity.SUCCESS ||
    legacyConveyancerFlag?.severity === checkSeverity.SUCCESS
  );
});

const isConfirmingSellerConveyancer = ref(false);

const isSellerConveyancerConfirmed = computed(() => {
  const saleReadyFinalisedFlag = filterFlagsByName(
    "sellerSelectConveyancer",
    checks.value,
    true
  );
  return saleReadyFinalisedFlag?.severity === checkSeverity.SUCCESS;
});

const confirmSellerConveyancer = async (transactionId, options) => {
  isConfirmingSellerConveyancer.value = true;
  const confirmSellerConveyancer = httpsCallable(
    functions,
    "collectors-sellerSelectConveyancer",
    {
      limitedUseAppCheckTokens: FIREBASE_ENABLE_LIMITED_USE_APP_CHECK_TOKENS,
    }
  );

  try {
    await confirmSellerConveyancer({ transactionId, ...options });
  } catch (ex) {
    Honeybadger.notify(ex, {
      name: "saleReady.js",
      message: `Failed to confirm the seller's conveyancer`,
      params: {
        transactionId,
        options,
      },
    });
    isConfirmingSellerConveyancer.value = false;
    throw ex;
  }
};

export default function useSaleConveyancing() {
  return {
    confirmSellerConveyancer,
    hasSellerConveyancer,
    isConfirmingSellerConveyancer,
    isInstructed,
    isSaleReady,
    isSellerConveyancerConfirmed,
  };
}
