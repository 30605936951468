import axios from "axios";
import useAuth from "@/composables/auth";
import { MOVEREADY_PDTF_API_URL, LOQATE_API_KEY } from "@/config";
import { getAppCheckToken } from "@/firebase";
import { ref } from "vue";
import Honeybadger from "@honeybadger-io/js";

const { getAccessToken } = useAuth();

const LOQATE_COUNTRIES_ISO2 = "GB";
const address = ref(null);
const addressError = ref(null);
const homeNationsAllowList = ["England", "Wales"];
const isFindingAddress = ref(false);
let findAddressBySearchQueryController;

const findAddressBySearchQuery = async (query) => {
  const params = {
    Key: LOQATE_API_KEY,
    Text: query,
    Countries: LOQATE_COUNTRIES_ISO2,
  };

  if (findAddressBySearchQueryController) {
    findAddressBySearchQueryController.abort();
  }

  findAddressBySearchQueryController = new AbortController();

  try {
    const response = await axios.get(
      `https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws`,
      {
        signal: findAddressBySearchQueryController.signal,
        params,
      }
    );
    addressError.value = null;
    return response.data.Items;
  } catch (ex) {
    if (axios.isCancel(ex)) return;

    addressError.value = ex;
    throw new Error("Address search is not available");
  }
};

/**
 * Only a type of 'Address' is a successful search result from Loqate.
 * Other types might be 'Postcode', 'BuildingNumber', which need to be expanded.
 * To get a 'Postcode' result, just enter any postcode.
 * To get a 'BuildingNumber' result, enter '176 Rodbourne Road'.
 */
let findChildAddressByParentIdController;
const findChildAddressByParentId = async (loqateAddressId) => {
  if (findChildAddressByParentIdController) {
    findChildAddressByParentIdController.abort();
  }

  findChildAddressByParentIdController = new AbortController();
  const params = {
    Key: LOQATE_API_KEY,
    Container: loqateAddressId,
    Countries: LOQATE_COUNTRIES_ISO2,
  };

  try {
    const response = await axios.get(
      `https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws`,
      {
        signal: findChildAddressByParentIdController.signal,
        params,
      }
    );

    addressError.value = null;
    return response.data.Items;
  } catch (ex) {
    if (axios.isCancel(ex)) return;

    addressError.value = ex;
    throw new Error("Address search is not available");
  }
};

const findAddressDataById = async (loqateAddressId) => {
  const userAccessToken = await getAccessToken();
  const appCheckToken = await getAppCheckToken();

  try {
    const response = await axios.get(
      `${MOVEREADY_PDTF_API_URL}/address/search/${loqateAddressId}/UPRN`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    addressError.value = null;
    const address = response.data;

    // Napier Road, Reading, RG1 8AF doesn't return a Home Nation!
    // Only throw exception if we can confirm the address is not in E/W?
    if (
      address.homeNation !== undefined &&
      address.homeNation !== "" &&
      !homeNationsAllowList.includes(address.homeNation)
    ) {
      throw new Error(
        `The property must be in ${homeNationsAllowList.join(
          " or "
        )} (this is located in ${address.homeNation})`
      );
    }
    return address;
  } catch (ex) {
    addressError.value = ex;
    Honeybadger.notify(ex, {
      name: "findAddressDataById",
      params: {
        loqateAddressId: loqateAddressId,
      },
    });
    throw ex;
  }
};

const findAddressByUprn = async (uprn) => {
  isFindingAddress.value = true;
  const appCheckToken = await getAppCheckToken();

  try {
    const response = await axios.get(
      `${MOVEREADY_PDTF_API_URL}/address/${uprn}`,
      {
        headers: {
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    address.value = response.data;
    addressError.value = null;
    return response.data;
  } catch (ex) {
    addressError.value = ex;
    throw ex;
  } finally {
    isFindingAddress.value = false;
  }
};

const formatSearchResult = (result) => {
  const { Description, Text } = result || {};

  if (!Text) return [];

  if (Description) return Text.split(", ").concat(Description.split(", "));

  return Text.split(", ");
};

export default function useAddressSearch() {
  return {
    address,
    addressError,
    findAddressBySearchQuery,
    findAddressBySearchQueryController,
    findAddressByUprn,
    findAddressDataById,
    findChildAddressByParentId,
    findChildAddressByParentIdController,
    formatSearchResult,
    isFindingAddress,
  };
}
