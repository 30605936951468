<template>
  <span
    class="pending"
    :class="{
      'pending--weak-icon': isWeakIcon,
      'pending--weakest': isWeakest,
      'pending--weak': isWeak,
      'pending--centered': isCentered,
      'pending--strong': isStrong,
    }"
  />
</template>

<script>
export default {
  props: {
    isWeak: {
      type: Boolean,
      default: false,
    },
    isWeakest: {
      type: Boolean,
      default: false,
    },
    isStrong: {
      type: Boolean,
      default: false,
    },
    isCentered: {
      type: Boolean,
      default: false,
    },
    // Can't think of a better term for this...
    isWeakIcon: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
