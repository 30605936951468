<template>
  <div
    class="field-input field-input-toggle"
    :class="{
      'field-input-toggle--invalid': !!validationFails,
      'field-input-toggle--checked': isChecked,
      'field-input--disabled field-input-toggle--disabled': disabled,
    }"
  >
    <label
      class="input-toggle__option"
      data-test="field-input-toggle-option-label"
    >
      <input
        type="checkbox"
        :id="inputId"
        :name="inputName"
        :aria-invalid="validationFails"
        :aria-describedby="describedByIds"
        class="input-toggle__option__input"
        :value="value"
        v-model="inputValue"
        :required="!isOptional"
        :disabled="disabled"
      />
      <span
        aria-hidden="true"
        class="input-toggle__option__signifier"
        :data-test="`${inputName}-signifier`"
      >
        <span class="input-toggle__option__signifier__icon">
          <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="input-toggle__option__signifier__icon__canvas"
            width="24"
            height="24"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
              d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"
              class="input-toggle__option__signifier__icon__glyph"
            /></svg></span
      ></span>
      <span
        class="input-toggle__option__label"
        :class="{ 'visually-hidden': isLabelVisuallyHidden }"
      >
        <slot name="label"><span v-html="label" /></slot>
      </span>
    </label>
    <div
      class="field__description field-input-toggle__description"
      v-if="hasDescription"
    >
      <slot name="description"><span v-html="description" /></slot>
    </div>
  </div>
</template>

<script>
import useInputs from "@/composables/inputs";
import { computed, getCurrentInstance } from "vue";

const { generateInputId, useInputValue } = useInputs();

export default {
  name: "InputCheckbox",
  emits: ["update:modelValue"],
  props: {
    isOptional: {
      type: Boolean,
      default: false,
    },
    isLabelVisuallyHidden: {
      type: Boolean,
      default: false,
    },
    verifiedSource: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    value: {
      type: [Boolean, Array, Object, String, Number],
      default: false,
    },
    modelValue: [Boolean, Array, Object],
    validation: {
      type: Object,
      default: () => {
        return {
          $invalid: false,
          $errors: [],
        };
      },
    },
  },
  setup(props, { emit, slots }) {
    const instance = getCurrentInstance();

    const parentInputName = instance.parent.ctx.inputName || null;

    const computedInputId = props.id || generateInputId();

    let inputId;

    const isChecked = computed(
      () =>
        Array.isArray(props.modelValue) &&
        props.modelValue.includes(props.value)
    );

    if (parentInputName) {
      inputId = `${parentInputName}-${computedInputId}`;
    } else {
      inputId = computedInputId;
    }

    const inputName = parentInputName || props.name || inputId;

    const inputValue = useInputValue(props, emit);

    const validationFails = computed(() => {
      return !!props.validation.$errors.length;
    });

    const describedByIds = computed(() => {
      const messageIds = props.validation.$errors.reduce(
        (messageIdCollection, error) => {
          const { $property, $validator } = error;

          const messageId = `validation-${$property}-${$validator}`;

          return [...messageIdCollection, messageId];
        },
        []
      );

      return messageIds.join(" ");
    });

    const hasDescription = computed(
      () => slots["description"] || props.description
    );

    const onChange = (event) => {
      emit("change", event.target.checked);
    };

    return {
      onChange,
      describedByIds,
      hasDescription,
      inputId,
      inputName,
      inputValue,
      isChecked,
      validationFails,
    };
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/variables/_field.scss";

$input-toggle-width: 40px;

.input-toggle__option {
  align-items: center;
  display: flex;
  margin: stack() 0 0 0;
  position: relative;
}

.input-toggle__option__input {
  left: -9999em;
  position: absolute;

  &:checked {
    & + .input-toggle__option__signifier {
      background: $color-primary;
      border-color: $color-primary;

      .input-toggle__option__signifier__icon {
        left: 16px;

        .input-toggle__option__signifier__icon__glyph {
          opacity: 1;
        }
      }
    }
  }
}

.input-toggle__option__signifier {
  background: $field-content-border-color;
  border-radius: 12px;
  border: $field-content-border;
  display: block;
  height: 24px;
  margin-right: gutter();
  margin-bottom: auto;
  min-width: 40px;
  width: $input-toggle-width;
  cursor: pointer;
}

.input-toggle__option__signifier__icon {
  @include transition();

  background: #fff;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  border-radius: 10px;
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  position: relative;
  vertical-align: bottom;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.input-toggle__option__signifier__icon__canvas {
  height: 16px;
  width: 16px;
}

.input-toggle__option__signifier__icon__glyph {
  @include transition();
  opacity: 0;
  fill: $color-secondary-800;
}

.field-input-toggle--invalid {
  .input-toggle__option__signifier {
    border-color: $color-danger;
  }
}

.input-toggle__option__label {
  font-weight: 500;
}

.field-input-toggle__description {
  margin-left: $input-toggle-width + gutter();
  margin-right: gutter();
}

.input-toggle__option + .field-input-toggle__description {
  margin-top: stack();
}

.input-toggle__option__input {
  &:focus {
    & + .input-toggle__option__signifier {
      box-shadow: 0 0 0 $button-focus-border-width $field-content-focus-color;
    }
  }
}
</style>
