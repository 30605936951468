export default [
  {
    path: "/terms",
    name: "legal.terms",
    component: () => import("../../views/TermsAndConditions.vue"),
    meta: {
      title: "Terms and conditions",
    },
  },
  {
    path: "/privacy",
    name: "legal.privacy",
    component: () => import("../../views/PrivacyPolicy.vue"),
    meta: {
      title: "Privacy policy",
    },
  },
  {
    path: "/cookies",
    name: "legal.cookies",
    component: () => import("../../views/CookieUse.vue"),
    meta: {
      title: "Cookie use",
    },
  },
];
