import { auth } from "@/firebase";

const requiresAuth = (to, from, next) => {
  if (auth?.currentUser) {
    next();
  } else {
    next({
      name: "sign-in",
      query: {
        returnUrl: to.fullPath,
      },
    });
  }
};

export default requiresAuth;
