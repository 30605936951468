import disposalType from "@/enums/disposal";
import marketingTenure from "@/enums/marketing-tenure";
import mediaType from "@/enums/media-type";
import { priceQualifier } from "@/enums/pdtf";
import { reapitPriceQualifier } from "@/enums/reapit";
import transactionStates from "@/enums/transaction-states";
import {
  reapitTenureType,
  reapitDisposal,
  reapitMediaType,
  reapitRentFrequency,
  reapitDepositType,
  reapitMarketingMode,
} from "@/enums/reapit";
import { rentFrequency } from "@/enums/pdtf";

const mapReapitPriceQualifierToSchema = (reapitPriceQualifer) => {
  const qualifierMap = {
    [reapitPriceQualifier.GUIDE_PRICE]: priceQualifier.GUIDE_PRICE,
    [reapitPriceQualifier.OFFERS_IN_REGION]: priceQualifier.OFFERS_IN_REGION,
    [reapitPriceQualifier.OFFERS_IN_EXCESS]: priceQualifier.OFFERS_IN_EXCESS,
    [reapitPriceQualifier.FIXED_PRICE]: priceQualifier.FIXED_PRICE,
  };

  return qualifierMap[reapitPriceQualifer] || null;
};

const mapReapitTenureTypeToSchema = (tenureType) => {
  const tenureMap = {
    [reapitTenureType.COMMONHOLD]: marketingTenure.COMMONHOLD,
    [reapitTenureType.FREEHOLD]: marketingTenure.FREEHOLD,
    [reapitTenureType.LEASEHOLD]: marketingTenure.LEASEHOLD,
    [reapitTenureType.SHARE_OF_FREEHOLD]: marketingTenure.SHARE_OF_FREEHOLD,
  };

  return tenureMap[tenureType] || null;
};

const mapReapitDisposalToSchema = (disposal) => {
  const disposalMap = {
    [reapitDisposal.AUCTION]: disposalType.AUCTION,
    [reapitDisposal.PRIVATE_TREATY]: disposalType.PRIVATE_TREATY,
  };

  return disposalMap[disposal] || null;
};

const mapReapitAddressToSchema = (reapitAddress) => {
  const address = {
    line2: reapitAddress.line2,
    line3: reapitAddress.line3,
    postcode: reapitAddress.postcode,
  };
  const line1Parts = [];

  if (reapitAddress?.buildingName) {
    line1Parts.push(reapitAddress.buildingName);
  }

  if (reapitAddress?.buildingNumber) {
    line1Parts.push(
      `${reapitAddress.buildingNumber} ${reapitAddress.line1}`.trim()
    );
  } else {
    line1Parts.push(reapitAddress.line1);
  }

  return { ...address, line1: line1Parts.join(", ") };
};

const mapReapitMediaTypeToSchema = (mediaTypeToMap) => {
  const map = {
    [reapitMediaType.PHOTOGRAPH]: mediaType.IMAGE,
    [reapitMediaType.FLOORPLAN]: mediaType.FLOORPLAN,
    [reapitMediaType.MAP]: mediaType.IMAGE,
    [reapitMediaType.EPC]: mediaType.EPC,
  };

  return map[mediaTypeToMap] || null;
};

const mapReaitRentFrequencyToSchema = (frequency) => {
  const map = {
    [reapitRentFrequency.WEEKLY]: rentFrequency.WEEKLY,
    [reapitRentFrequency.MONTHLY]: rentFrequency.MONTHLY,
    [reapitRentFrequency.ANNUALLY]: rentFrequency.YEARLY,
  };

  return map[frequency] || null;
};

/**
 * In Reapit the `amount` is either a currency value, or a multiplier for a type
 * that is either number of weeks or months rent.
 */
const mapReapitDespositToSchema = (
  depositType,
  amount,
  rent,
  rentFrequency
) => {
  if (depositType === reapitDepositType.FIXED && amount && !isNaN(amount)) {
    return amount;
  }

  if (
    depositType === reapitDepositType.WEEKS &&
    amount &&
    rent &&
    rentFrequency === reapitRentFrequency.WEEKLY
  ) {
    return rent * amount;
  }

  if (
    depositType === reapitDepositType.MONTHS &&
    amount &&
    rent &&
    rentFrequency === reapitRentFrequency.MONTHLY
  ) {
    return rent * amount;
  }

  return null;
};

const mapReapitPropertyToSchemaStatusSaleOrLet = (reapitProperty) => {
  const marketingMode = reapitProperty?.marketingMode;

  if (marketingMode === reapitMarketingMode.SELLING) {
    return transactionStates.FOR_SALE;
  }

  if (marketingMode === reapitMarketingMode.LETTING) {
    return transactionStates.TO_LET;
  }

  return null;
};

export default function useReapitMapping() {
  return {
    mapReaitRentFrequencyToSchema,
    mapReapitAddressToSchema,
    mapReapitDespositToSchema,
    mapReapitDisposalToSchema,
    mapReapitMediaTypeToSchema,
    mapReapitPriceQualifierToSchema,
    mapReapitPropertyToSchemaStatusSaleOrLet,
    mapReapitTenureTypeToSchema,
  };
}
