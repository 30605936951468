import Honeybadger from "@honeybadger-io/js";
import gtmEvent from "@/enums/gtm-events";
import { reactive } from "vue";
import { useGtm } from "@gtm-support/vue-gtm";

const setEssentialConsent = () => {
  setConsent({
    marketing: false,
    analytics: false,
    preferences: false,
    necessary: true,
  });
};

const setAllConsent = () => {
  setConsent({
    marketing: true,
    analytics: true,
    preferences: true,
    necessary: true,
  });
};

const setConsent = (consent) => {
  const gtm = useGtm();
  const consentMode = {
    ad_storage: consent.marketing ? "granted" : "denied",
    ad_user_data: consent.marketing ? "granted" : "denied",
    ad_personalization: consent.marketing ? "granted" : "denied",
    analytics_storage: consent.analytics ? "granted" : "denied",
    personalization: consent.preferences ? "granted" : "denied",
    functionality_storage: consent.necessary ? "granted" : "denied",
    security_storage: consent.necessary ? "granted" : "denied",
  };
  window.gtag("consent", "update", consentMode);
  // IMPORTANT: this forces Tag Manger to trigger a tag without reloading the page.
  gtm.trackEvent({
    event: gtmEvent.CONSENT_UPDATE,
  });
  saveConsentMode(consentMode);
};

const saveConsentMode = (consentMode) => {
  localStorage.setItem("consentMode", JSON.stringify(consentMode));
  setSavedPreferencesFromConsentMode(consentMode);
};

const deleteConsentMode = () => {
  localStorage.removeItem("consentMode");
};

const savedPreferences = reactive({
  marketing: false,
  analytics: false,
  preferences: false,
  necessary: true,
});

const setSavedPreferencesFromConsentMode = (consentMode) => {
  savedPreferences.marketing = consentMode?.ad_storage === "granted";
  savedPreferences.analytics = consentMode?.analytics_storage === "granted";
  savedPreferences.preferences = consentMode?.personalization === "granted";
  savedPreferences.necessary = true;
};

const fetchSavedConsentMode = () => {
  const consentMode = localStorage.getItem("consentMode");

  if (consentMode !== null) {
    try {
      setSavedPreferencesFromConsentMode(JSON.parse(consentMode));
    } catch (ex) {
      Honeybadger.notify(ex, {
        message: "Can't parse consentMode from localStorage",
        name: "cookieConsent.js",
        params: {
          consentMode,
        },
      });
    }
  }

  return consentMode;
};

export default function useCookieConsent() {
  return {
    deleteConsentMode,
    fetchSavedConsentMode,
    savedPreferences,
    setAllConsent,
    setConsent,
    setEssentialConsent,
  };
}
