import useSiteLayout from "@/composables/siteLayout";
import { updateMetaTags } from "./guards/metaTags";

const updateLayout = ({ router }) => {
  const {
    setDefaultLayout,
    setLayout,
    setIsAccountPage,
    setDefaultIsAccountPage,
  } = useSiteLayout();

  router.beforeEach((to, from, next) => {
    const theClosestSpecifiedLayout = (r) => {
      return r.meta && r.meta.layout;
    };

    const theClosestSpecifiedBodyClass = (r) => {
      return r.meta && r.meta.bodyClass;
    };

    const theClosestSpecifiedAccountPageFlag = (r) => {
      return r.meta && r.meta.isAccountPage;
    };

    const accountPageMetaMatch = to.matched
      .slice()
      .reverse()
      .find(theClosestSpecifiedAccountPageFlag);

    const metaMatch = to.matched
      .slice()
      .reverse()
      .find(theClosestSpecifiedLayout);

    const bodyClassMatch = to.matched
      .slice()
      .reverse()
      .find(theClosestSpecifiedBodyClass);

    if (accountPageMetaMatch) {
      setIsAccountPage(accountPageMetaMatch.meta.isAccountPage);
    } else {
      setDefaultIsAccountPage();
    }

    if (metaMatch) {
      setLayout(metaMatch.meta.layout);
    } else {
      setDefaultLayout();
    }
    document.body.classList.remove(...document.body.classList);

    if (bodyClassMatch && document?.body) {
      document.body.classList.add(bodyClassMatch.meta.bodyClass);
    }

    updateMetaTags(to.matched[0]?.meta?.metaTags || []);

    next();
  });
};

export { updateLayout };
