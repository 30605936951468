/**
 * Backend requires enum keys, but handling that in the FE
 * is a pain, so only convert the value to it's
 * key if needed.
 */
const getKeyByValue = (valueToFind, enumToSearch) => {
  return Object.keys(enumToSearch)[
    Object.values(enumToSearch).indexOf(valueToFind)
  ];
};

export default function useEnumUtils() {
  return {
    getKeyByValue,
  };
}
