import axios from "axios";
import useAuth from "@/composables/auth";
import { MOVEREADY_ORGANISATION_API_URL } from "@/config";
import { getAppCheckToken } from "@/firebase";
import { ref, computed } from "vue";
import crmName from "@/enums/crm-name";

const { getAccessToken } = useAuth();

const isFetchingOrganisationUsers = ref(false);
const organisationUsers = ref([]);
let hasFetchedOrganisationUsers = false;

const fetchOrganisationUsers = async (organisationId) => {
  const appCheckToken = await getAppCheckToken();
  const userAccessToken = await getAccessToken();
  isFetchingOrganisationUsers.value = true;

  try {
    const organisationUsersResponse = await axios.get(
      `${MOVEREADY_ORGANISATION_API_URL}/organisations/${organisationId}/users`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    // Rename the property officesIds to officeIds because it's not the conventions used in the FE.
    organisationUsers.value = organisationUsersResponse.data.map((user) => ({
      ...user,
      officeIds: user.officesIds,
    }));
  } catch (ex) {
    organisationUsers.value = [];
  } finally {
    hasFetchedOrganisationUsers = true;
    isFetchingOrganisationUsers.value = false;
  }
};

const getIntegrationsForAllUsers = (organisationUsers) => {
  return organisationUsers.reduce((acc, user) => {
    return [...acc, ...(user?.crmIntegrations || [])];
  }, []);
};

const isAltoIntegrationAvailable = computed(() => {
  const integrationsForAllUsers = getIntegrationsForAllUsers(
    organisationUsers.value
  );

  const hasAltoIntegration = integrationsForAllUsers.some(
    (integration) => integration?.crmName === crmName.ALTO
  );

  return hasAltoIntegration;
});

/**
 * Checking all users for valid Alto integration. In case
 * some users don't have it enabled, this will disable it for
 * all users in the organisation. In taht case the invite/accept
 * flow hasn't copied the integration record accross.
 */
const isAltoIntegrationActive = computed(() => {
  const integrationsForAllUsers = getIntegrationsForAllUsers(
    organisationUsers.value
  );
  const altoIntegrations = integrationsForAllUsers.filter(
    (integration) => integration?.crmName === crmName.ALTO
  );
  const activeIntegrations = altoIntegrations.filter(({ accountId }) => {
    return accountId !== undefined && accountId !== "";
  });

  return (
    altoIntegrations.length > 0 &&
    activeIntegrations.length === altoIntegrations.length
  );
});

export default function useOrganisationUsers() {
  return {
    fetchOrganisationUsers,
    isAltoIntegrationActive,
    isAltoIntegrationAvailable,
    isFetchingOrganisationUsers,
    hasFetchedOrganisationUsers,
    organisationUsers,
  };
}
