const pluralize = (count, one, many) => {
  if (count === 1) return one;

  return many;
};

const toTitleCase = (dirtyString) => {
  return dirtyString
    .split(" ")
    .map((word) => {
      const firstChar = word.charAt(0);

      // Handle "29A"
      if (isNaN(firstChar)) {
        return `${firstChar.toUpperCase()}${word.slice(1).toLowerCase()}`;
      }
      return word;
    })
    .join(" ");
};

const format = (template, ...args) => {
  return template.replace(/{([0-9]+)}/g, function (match, index) {
    return typeof args[index] === "undefined" ? match : args[index];
  });
};

export default function useText() {
  return {
    format,
    pluralize,
    toTitleCase,
  };
}
