import { auth } from "@/firebase";
import { APP_URL } from "@/config";

const isSameOrigin = (url, urlToCompare) => {
  try {
    const urlObject = new URL(url);
    const urlObjectToCompare = new URL(urlToCompare);
    return urlObject.origin === urlObjectToCompare.origin;
  } catch (e) {
    return false;
  }
};

const redirectIfLoggedIn = (redirectionRoute) => {
  return (to, from, next) => {
    const { continueUrl } = to.query;

    if (auth.currentUser) {
      // Override the default redirect route if a continueUrl is provided.
      if (continueUrl && isSameOrigin(APP_URL, continueUrl)) {
        window.location.href = continueUrl;
        return;
      }

      if (redirectionRoute) return next(redirectionRoute);
    } else {
      next();
    }
  };
};

export default redirectIfLoggedIn;
