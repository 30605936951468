import useConveyancer from "@/composables/conveyancing/conveyancer";

const { hasConveyancer, addConveyancerValidation } = useConveyancer();

const redirectIfNoConveyancerParticipant = (to, from, next) => {
  addConveyancerValidation.value.$touch();
  const isFormValid = !addConveyancerValidation.value.$invalid;

  if (!hasConveyancer.value && !isFormValid) {
    const { transactionId } = to.params;

    return next({
      name: "participant.conveyancer.add",
      params: { transactionId },
    });
  }

  return next();
};

export default redirectIfNoConveyancerParticipant;
