import useUserProfile from "@/composables/user/userProfile";

const {
  userTransactions,
  fetchUserProfile,
  isExecutive,
  isApplicantForTransaction,
} = useUserProfile();

const redirectUserHome = async (to, from, next) => {
  await fetchUserProfile();

  if (isExecutive.value) {
    return next({
      name: "back-office.dashboard",
    });
  }

  if (userTransactions.value.length === 1) {
    const [singleUserTransRel] = userTransactions.value;
    const transactionId = singleUserTransRel.transactionId;

    if (isApplicantForTransaction(transactionId)) {
      return next({
        name: "participants.identity.introduction",
        params: { transactionId: transactionId },
      });
    }

    return next({
      name: "property.view",
      params: { transactionId: transactionId },
    });
  }

  next({
    name: "mover.dashboard",
  });
};

export default redirectUserHome;
