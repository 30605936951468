import { ref } from "vue";
import { APP_URL } from "@/config";

// Used to determine if the branding request has been attempted. Do not change from null.
const isCoBrandingEnabled = ref(null);
const brandName = ref("");
const brandLogo = ref("");
const reapitBrandLogo = ref("");
const brandLogoScale = ref("");

const setDefaultBranding = () => {
  isCoBrandingEnabled.value = false;
  brandLogoScale.value = "";
  brandName.value = "Moverly";
  brandLogo.value = `${APP_URL}/assets/images/logos/moverly-back-office.svg`;
};

const setBranding = (brandingPreferences) => {
  if (brandingPreferences?.isCoBrandingEnabled) {
    isCoBrandingEnabled.value = true;
    brandLogoScale.value = brandingPreferences?.brandLogoScale || "";
    brandName.value = brandingPreferences?.brandName || "Moverly";
    const brandLogoFilename = brandingPreferences?.brandLogo;
    const reapitBrandLogoFilename = brandingPreferences?.reapitBrandLogo;

    if (brandLogoFilename) {
      brandLogo.value = `${APP_URL}/assets/images/logos/${brandLogoFilename}`;
    } else {
      brandLogo.value = `${APP_URL}/assets/images/logos/moverly-back-office.svg`;
    }

    if (reapitBrandLogoFilename) {
      reapitBrandLogo.value = `${APP_URL}/assets/images/logos/${reapitBrandLogoFilename}`;
    } else {
      reapitBrandLogo.value = "";
    }
  }
};

setDefaultBranding();

export default function useBackOfficeBranding() {
  return {
    brandLogo,
    brandLogoScale,
    brandName,
    isCoBrandingEnabled,
    reapitBrandLogo,
    setBranding,
    setDefaultBranding,
  };
}
