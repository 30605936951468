export default Object.freeze({
  ACTIVE: "active", // initial state if we don't know sales/lettings
  FOR_SALE: "For sale",
  UNDER_OFFER: "Under offer",
  SSTC: "Sold subject to contract",
  EXCHANGED: "Contracts exchanged",
  COMPLETED: "Completed",
  CANCELLED: "Cancelled",
  TO_LET: "To let",
  LET_AGREED: "Let agreed",
});
