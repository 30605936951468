import routes from "./routes";
import setRouteTitle from "./guards/setRouteTitle";
import loadUserProfile from "./guards/loadUserProfile";
import { APP_TITLE } from "@/config";
import { createRouter, createWebHistory } from "vue-router";
import { updateLayout } from "./guards";

const resolveShortUrlRoute = {
  path: "/s/:slug",
  name: "resolveShortUrl",
  component: () => import("../views/RedirectUrl.vue"),
  meta: {
    layout: "ModalLayout",
    title: "Redirect",
  },
};

const errorRoute = {
  path: "/error",
  name: "500",
  props: (route) => {
    return {
      message: route.query.message,
    };
  },
  component: () => import("../views/ErrorPage.vue"),
  meta: {
    title: "Error",
  },
};

export const notFoundRoute = {
  path: "/:pathMatch(.*)*",
  name: "404",
  component: () => import("../views/NotFound.vue"),
  meta: {
    title: "Page not found",
  },
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [resolveShortUrlRoute, ...routes, errorRoute, notFoundRoute],
  scrollBehavior(to) {
    if (to.hash) {
      return { el: to.hash };
    }

    return { top: 0 };
  },
});

setRouteTitle({ router, appName: APP_TITLE });
updateLayout({ router });
router.beforeEach(loadUserProfile);

export default router;
