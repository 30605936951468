/**
 * External access for back-end PDF generation.
 */

import requiresAuth from "../guards/requiresAuth";
import impersonateUser from "../guards/impersonateUser";

export default [
  {
    name: "baspi.full-view",
    path: "/baspi-full-view/:transactionId",
    beforeEnter: requiresAuth,
    component: () => import("../../views/BASPIFullView.vue"),
    meta: {
      title: "BASPI full view",
    },
  },
  {
    name: "baspi.full-view.print",
    path: "/baspi-full-view/:transactionId/print",
    beforeEnter: impersonateUser,
    component: () => import("../../views/BASPIFullViewPrint.vue"),
    meta: {
      title: "BASPI full view",
      layout: "PrintLayout",
    },
  },
];
