import { auth } from "@/firebase";
import redirectBasedOnInviteStatus from "../guards/redirectBasedOnInviteStatus";
import redirectBasedOnOrganisationInviteStatus from "../guards/redirectBasedOnOrganisationInviteStatus";
import { APP_URL } from "@/config";

const redirectToAcceptInviteIfLoggedIn = (to, from, next) => {
  /* The emulator replaces the on-site login and automatically uses the continueUrl
   * "skipping" a step that would happen when the app is deployed. This
   * redirect puts both local emulators and hosted apps on the same page.
   *  The emulator uses the magic link host of "http://127.0.0.1:9099/emulator/action"
   * whereas the only option we can see to configure in Firebase is "http://staging.moverly.com/sign-in/confirm"
   * in the email templates config page.
   */
  if (!auth.currentUser) {
    const continueUrl = encodeURIComponent(window.location.href);
    window.location.href = `${APP_URL}/sign-up${window.location.search}&continueUrl=${continueUrl}`;
    return;
  }

  if (auth.currentUser) {
    return next({
      name: "invites.accept",
      params: { ...to.params },
      query: { ...to.query },
    });
  }

  next();
};
const redirectToAcceptOrganisationInviteIfLoggedIn = (to, from, next) => {
  /* The emulator replaces the on-site login and automatically uses the continueUrl
   * "skipping" a step that would happen when the app is deployed. This
   * redirect puts both local emulators and hosted apps on the same page.
   *  The emulator uses the magic link host of "http://127.0.0.1:9099/emulator/action"
   * whereas the only option we can see to configure in Firebase is "http://staging.moverly.com/sign-in/confirm"
   * in the email templates config page.
   */

  if (!auth.currentUser) {
    const continueUrl = encodeURIComponent(window.location.href);
    window.location.href = `${APP_URL}/sign-up${window.location.search}&continueUrl=${continueUrl}`;
    return;
  }

  if (auth.currentUser) {
    return next({
      name: "invites.organisation.accept",
      params: { ...to.params },
      query: { ...to.query },
    });
  }

  next();
};

export default [
  {
    path: "/invite/:transactionId",
    component: {
      template: "<router-view></router-view>",
    },
    meta: {
      title: "Invite",
      isAccountPage: true,
    },
    children: [
      {
        name: "invites.auth.confirm",
        path: "confirm",
        beforeEnter: redirectToAcceptInviteIfLoggedIn,
        component: () => import("../../views/invites/ConfirmInvite.vue"),
        meta: {
          title: "Confirm sign-in",
          bodyClass: "body--auth",
        },
      },
      {
        name: "invites.accept",
        path: "confirm",
        beforeEnter: redirectBasedOnInviteStatus,
        component: () => import("../../views/invites/InviteState.vue"),
        meta: {
          title: "Accept",
        },
      },
      {
        name: "invites.error",
        path: "error",
        component: () => import("../../views/invites/InvitesError.vue"),
        meta: {
          title: "Error",
        },
      },
    ],
  },
  {
    name: "invites.organisation.confirm",
    path: "/invite/organisations/:organisationId/confirm",
    beforeEnter: redirectToAcceptOrganisationInviteIfLoggedIn,
    component: () => import("../../views/invites/ConfirmInvite.vue"),
    meta: {
      title: "Confirm sign-in",
      bodyClass: "body--auth",
    },
  },
  {
    name: "invites.organisation.accept",
    path: "/invite/organisations/:organisationId/confirm",
    beforeEnter: redirectBasedOnOrganisationInviteStatus,
    component: () => import("../../views/invites/InviteState.vue"),
    meta: {
      title: "Accept organisation invite",
    },
  },
];
