import useOrganisationInvites from "@/composables/organisation/organisationInvites";
import useUserProfile from "@/composables/user/userProfile";
import useAuth from "@/composables/auth";

const redirectBasedOnInviteStatus = async (to, from, next) => {
  const { token } = to.query;
  const { organisationId } = to.params;
  const { acceptOrganisationInvite } = useOrganisationInvites();
  const { fetchUserProfile, createUserProfile } = useUserProfile();
  const { userId, userEmail } = useAuth();

  try {
    await createUserProfile({
      userId: userId.value,
      email: userEmail.value,
      token,
    });
  } catch (ex) {
    console.warn("Profile not saved; may already exist.");
  }

  try {
    await acceptOrganisationInvite({
      inviteeUserId: userId.value,
      organisationId,
      token,
    });

    // Refresh the user profile now that it will have new organisation data.
    await fetchUserProfile();

    return next({
      name: "back-office.dashboard",
    });
  } catch (ex) {
    console.error(ex);
    const status = ex?.response?.status || "";

    return next({
      name: "invites.error",
      query: {
        status,
        token,
      },
    });
  }
};

export default redirectBasedOnInviteStatus;
