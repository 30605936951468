<template>
  <component :is="currentLayoutName" />
</template>

<script>
import BackOfficeLayout from "@/layouts/BackOfficeLayout";
import DefaultLayout from "@/layouts/DefaultLayout";
import EmptyLayout from "@/layouts/EmptyLayout";
import ModalLayout from "@/layouts/ModalLayout";
import useSiteLayout from "@/composables/siteLayout";
import useZendesk from "@/composables/zendesk";

export default {
  name: "App",
  components: {
    BackOfficeLayout,
    DefaultLayout,
    EmptyLayout,
    ModalLayout,
    PrintLayout: EmptyLayout,
  },
  setup() {
    const { currentLayoutName } = useSiteLayout();

    // Initialise Zendesk
    const zendesk = useZendesk();
    zendesk.zendeskSetupAuth();

    return {
      currentLayoutName,
    };
  },
};
</script>

<style lang="scss">
@import "./assets/styles/index.scss";
</style>
