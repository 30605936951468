import Honeybadger from "@honeybadger-io/js";
import axios from "axios";
import inviteStatus from "@/enums/invite-status";
import inviteStatusLabels from "@/enums/invite-status-label";
import useAuth from "@/composables/auth";
import { MOVEREADY_USER_API_URL } from "@/config";
import { getAppCheckToken } from "@/firebase";
import { ref } from "vue";

const { getAccessToken } = useAuth();
const invites = ref([]);
const isFindingInvites = ref(false);
const isDeletingInvite = ref(false);
const isUpdatingInvite = ref(false);

const findInvitesByTransaction = async (transactionId, inviterUserId) => {
  const userAccessToken = await getAccessToken();
  const appCheckToken = await getAppCheckToken();
  isFindingInvites.value = true;

  try {
    const response = await axios.get(
      `${MOVEREADY_USER_API_URL}/invites/users/${inviterUserId}/transactions/${transactionId}`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );

    invites.value = response?.data || [];
    // Returning for regression fix.
    return response?.data || [];
  } catch (ex) {
    // A 404 is no invites, but return an empty array instead.
    if (ex?.response?.status === 404) {
      invites.value = [];
      return [];
    }

    throw ex;
  } finally {
    isFindingInvites.value = false;
  }
};

const findInvites = (transactionIdCollection, inviterUserId) => {
  const requests = [];
  transactionIdCollection.forEach((transactionId) => {
    requests.push(findInvitesByTransaction(transactionId, inviterUserId));
  });

  return Promise.all(requests);
};

const createInvite = async ({
  transactionId,
  inviterUserId,
  inviteeEmail,
  inviteeFirstName,
  inviteeLastName,
  inviteeRole,
  inviteePhone,
  hasThirdPartyConsent = null,
  isIdentityCheckRequired,
}) => {
  const userAccessToken = await getAccessToken();
  try {
    const appCheckToken = await getAppCheckToken();

    await axios.post(
      `${MOVEREADY_USER_API_URL}/invites/users/${inviterUserId}/transactions/${transactionId}`,
      {
        email: inviteeEmail,
        role: inviteeRole,
        inviteeFirstName,
        inviteeLastName,
        phone: inviteePhone,
        isIdentityCheckRequired,
        hasThirdPartyConsent,
      },
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
  } catch (ex) {
    const errorMessage = ex?.response?.data?.error || ex.message;
    Honeybadger.notify(ex, {
      message: `Failed to create an invite: ${errorMessage}`,
      name: "invites.js",
      params: {
        transactionId,
        inviterUserId,
        inviteeEmail,
        inviteeFirstName,
        inviteeLastName,
        inviteeRole,
        inviteePhone,
        hasThirdPartyConsent,
        isIdentityCheckRequired,
      },
    });
    throw new Error(errorMessage);
  }
};

const deleteInvite = async (inviteId) => {
  const userAccessToken = await getAccessToken();
  const appCheckToken = await getAppCheckToken();
  isDeletingInvite.value = true;
  try {
    await axios.delete(`${MOVEREADY_USER_API_URL}/invites/${inviteId}`, {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
        ContentType: "application/json",
        "X-Firebase-AppCheck": appCheckToken,
      },
    });
  } catch (ex) {
    console.error(ex);
    throw ex;
  } finally {
    isDeletingInvite.value = false;
  }
};

const acceptInvite = async ({ inviteeUserId, transactionId, token }) => {
  const userAccessToken = await getAccessToken();
  const appCheckToken = await getAppCheckToken();

  try {
    await axios.patch(
      `${MOVEREADY_USER_API_URL}/invites/users/${inviteeUserId}/transactions/${transactionId}/token/${token}/accept`,
      {},
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
  } catch (ex) {
    // A 409 means that the invite has been accepted.
    if (ex?.response?.status === 409) {
      return ex.response;
    }

    throw ex;
  }
};

const updateInvite = async (inviteId, { isIdentityCheckRequired }) => {
  const userAccessToken = await getAccessToken();
  const appCheckToken = await getAppCheckToken();
  isUpdatingInvite.value = true;
  try {
    await axios.patch(
      `${MOVEREADY_USER_API_URL}/invites/${inviteId}`,
      { isIdentityCheckRequired },
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
  } catch (ex) {
    Honeybadger.notify(ex, {
      message: "Failed to update an invite",
      name: "invites.js",
      params: {
        inviteId,
        isIdentityCheckRequired,
      },
    });
    throw ex;
  } finally {
    isUpdatingInvite.value = false;
  }
};

export default function useInvites() {
  return {
    acceptInvite,
    createInvite,
    deleteInvite,
    findInvites,
    findInvitesByTransaction,
    inviteStatus,
    inviteStatusLabels,
    inviteStatuses: inviteStatus,
    invites,
    isDeletingInvite,
    isFindingInvites,
    updateInvite,
  };
}
