<template>
  <icon
    class="icon icon--disclosure"
    :class="{ 'icon--disclosure--active': isActive }"
  >
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      class="icon__canvas"
      width="24"
      height="24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"
        class="icon__glyph icon__glyph--disclosure"
      />
    </svg>
  </icon>
</template>

<script>
import Icon from "./IconBase";

export default {
  name: "DisclosureRight",
  components: {
    Icon,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.icon--disclosure--active {
  transform: rotate(180deg);
}
</style>
