<script setup>
import useReapit from "@/composables/reapit";
import useUserProfile from "@/composables/user/userProfile";

const { reapitConnectLogout } = useReapit();
const { isOrganisationOwner } = useUserProfile();
</script>

<template>
  <header class="reapit-back-office-site-header" role="banner">
    <div class="reapit-back-office-site-header__content">
      <router-link
        :to="{ name: 'back-office.dashboard' }"
        class="reapit-back-office-site-logo"
      >
        <svg
          class="reapit-back-office-site-logo__graphic"
          width="1em"
          height="1em"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M3 16.8 20 0l17 16.8V40H3V16.8Z" fill="#31448F"></path>
          <path d="M3 16.8 20 0l17 16.8V30L3 16.8Z" fill="#7BC9EB"></path>
          <path
            d="M37 40h-9.951L12.95 6.966 20 0l17 16.8V40Z"
            fill="#0061A8"
          ></path>
          <path
            d="M37 16.8V30l-17-6.517-7.049-16.517L20 0l17 16.8Z"
            fill="var(--nav-menu-icon-secondary-accent)"
          ></path></svg
      ></router-link>

      <div class="reapit-back-office-site-navigation" role="navigation">
        <ul class="reapit-back-office-site-navigation-menu">
          <li class="reapit-back-office-site-navigation-menu__item">
            <router-link
              :to="{ name: 'back-office.dashboard' }"
              class="reapit-back-office-site-navigation-submenu__anchor"
              active-class="reapit-back-office-site-navigation-submenu__anchor--active"
              ><svg
                width="1em"
                height="1em"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="reapit-back-office-site-navigation-submenu__icon"
              >
                <path
                  d="M8.541 16.377v10.058H30.46V16.377h-4.977v8.258h-3.494v-8.259h-4.976v8.26h-3.494v-8.26H8.54Z"
                  fill="var(--nav-menu-icon-secondary-accent)"
                ></path>
                <path
                  d="M32.206 14.788H6.794c-.814 0-1.098-1.085-.391-1.485l12.706-7.2a.794.794 0 0 1 .783 0l12.706 7.2c.708.401.42 1.485-.392 1.485ZM33 31.412v-1.8a1.59 1.59 0 0 0-1.588-1.589H7.588A1.59 1.59 0 0 0 6 29.612v1.8A1.59 1.59 0 0 0 7.588 33h23.824A1.59 1.59 0 0 0 33 31.412Z"
                  fill="var(--nav-menu-icon-primary-accent)"
                ></path></svg
              >Properties</router-link
            >
          </li>
          <li class="reapit-back-office-site-navigation-menu__item">
            <router-link
              :to="{ name: 'back-office.user.account' }"
              class="reapit-back-office-site-navigation-submenu__anchor"
              active-class="reapit-back-office-site-navigation-submenu__anchor--active"
              ><svg
                width="1em"
                height="1em"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="reapit-back-office-site-navigation-submenu__icon"
              >
                <path
                  d="M20 6C12.269 6 6 12.268 6 20c0 7.731 6.268 13.999 14 13.999s14-6.268 14-14C34 12.268 27.732 6 20 6Z"
                  fill="var(--nav-menu-icon-secondary-accent)"
                ></path>
                <path
                  d="M20 6C12.269 6 6 12.268 6 20c0 7.731 6.268 13.999 14 13.999s14-6.268 14-14C34 12.268 27.732 6 20 6Zm0 4.186a4.63 4.63 0 1 1 0 9.262 4.63 4.63 0 0 1 0-9.262Zm-.003 20.153c-2.552 0-4.888-.93-6.69-2.468a1.974 1.974 0 0 1-.693-1.5c0-2.593 2.099-4.668 4.692-4.668h5.389a4.663 4.663 0 0 1 4.685 4.669c0 .576-.252 1.124-.692 1.499a10.271 10.271 0 0 1-6.691 2.468Z"
                  fill="var(--nav-menu-icon-primary-accent)"
                ></path></svg
              >Account</router-link
            >
          </li>
          <li
            class="reapit-back-office-site-navigation-menu__item"
            v-if="isOrganisationOwner"
          >
            <router-link
              :to="{ name: 'back-office.organisation.details' }"
              class="reapit-back-office-site-navigation-submenu__anchor"
              active-class="reapit-back-office-site-navigation-submenu__anchor--active"
            >
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="reapit-back-office-site-navigation-submenu__icon"
              >
                <path
                  d="M20.444 13.645a4.237 4.237 0 0 1 4.232 4.231 4.237 4.237 0 0 1-4.232 4.232 4.237 4.237 0 0 1-4.231-4.232 4.237 4.237 0 0 1 4.231-4.232ZM15.265 25.138c1.032-1.862 2.979-3.03 5.18-3.03 2.2 0 4.147 1.168 5.18 3.03a7.515 7.515 0 0 1-5.18 2.048 7.515 7.515 0 0 1-5.18-2.048Z"
                  fill="var(--nav-menu-icon-secondary-accent)"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M31.208 13.726a12.551 12.551 0 0 1 1.598 3.858l2.083.506v4.708l-2.083.507a12.552 12.552 0 0 1-1.598 3.858l1.074 1.791-3.328 3.328-1.79-1.074a12.552 12.552 0 0 1-3.859 1.598l-.507 2.083h-4.707l-.507-2.083a12.551 12.551 0 0 1-3.858-1.598l-1.791 1.074-3.328-3.328 1.074-1.79a12.554 12.554 0 0 1-1.598-3.859L6 22.798v-4.707l2.083-.507a12.553 12.553 0 0 1 1.598-3.858l-1.074-1.791 3.328-3.328 1.79 1.074a12.553 12.553 0 0 1 3.859-1.598L18.09 6h4.708l.507 2.083c1.374.317 2.667.852 3.858 1.598l1.791-1.074 3.328 3.328-1.074 1.79ZM20.445 30.512c5.56 0 10.067-4.508 10.067-10.068s-4.508-10.067-10.067-10.067c-5.56 0-10.068 4.507-10.068 10.067 0 5.56 4.507 10.068 10.068 10.068Z"
                  fill="var(--nav-menu-icon-primary-accent)"
                ></path></svg
              >Settings</router-link
            >
          </li>
          <li
            class="reapit-back-office-site-navigation-menu__item reapit-back-office-site-navigation-menu__item--logout"
          >
            <button
              @click="reapitConnectLogout"
              :to="{ name: 'sign-out' }"
              data-test="logout"
              class="reapit-back-office-site-navigation-submenu__anchor"
              active-class="reapit-back-office-site-navigation-submenu__anchor--active"
            >
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="reapit-back-office-site-navigation-submenu__icon"
              >
                <path
                  d="M8.938 21.946h14.635a1.446 1.446 0 0 0 0-2.892H8.938l1.87-1.87a1.446 1.446 0 1 0-2.046-2.046l-4.338 4.339a1.442 1.442 0 0 0-.095.105l-.021.028a1.367 1.367 0 0 0-.064.086l-.016.026c-.02.031-.04.063-.057.095l-.01.02a1.45 1.45 0 0 0-.05.108l-.006.014c-.015.04-.03.079-.042.12l-.004.015c-.012.04-.022.08-.03.121-.003.012-.004.025-.006.038a1.35 1.35 0 0 0-.016.391c.004.036.01.07.016.105l.005.036c.009.041.02.082.031.122l.004.014c.012.041.027.08.043.12l.005.013c.015.038.033.074.051.11l.01.018c.017.033.036.065.057.096l.015.026c.02.03.042.058.064.086l.02.028c.03.036.062.071.096.105l4.338 4.338c.283.283.653.424 1.023.424a1.446 1.446 0 0 0 1.023-2.469l-1.87-1.87Z"
                  fill="var(--nav-menu-icon-secondary-accent)"
                ></path>
                <path
                  d="M23.015 8c-4.273 0-7.651 2.273-9.497 5.535-.482.7-.613 1.724-.094 2.483.865 1.262 2.474 1.12 2.87 1.12h5.573c3.62 0 4.769 1.681 4.769 3.362s-1.148 3.362-4.77 3.362h-5.572c-.983 0-1.722.56-2.296 1.12-.574.56-.812 1.768-.463 2.508 1.26 2.669 5.22 5.51 9.48 5.51 7.06 0 12.803-5.608 12.803-12.5 0-6.893-5.743-12.5-12.803-12.5Z"
                  fill="var(--nav-menu-icon-primary-accent)"
                ></path></svg
              >Logout
            </button>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>
